<template>

  <div class="mbg-city-selector w-md-75">
    <label v-if="title">{{title}}</label>

    <autocomplete :items="cities"
                  :input-class="hasCityError ? 'form-control is-invalid new-chart-input' : 'new-chart-input' "
                  :value="value"
                  :get-label="getLabel"
                  :component-item='template'
                  :auto-select-one-item="false"
                  @item-selected="citySelected"
                  :placeholder="$t('general.city')"
                  @change="updateCities"
                  :disabled="isFetchingCities || disabled"
    />
    <p class="form-error-indicator mb-0 mt-0" v-if="hasCityError">
      <span>Select (by clicking) a city from the list</span>
    </p>
  </div>
</template>

<script>
import Autocomplete from "../structure/Autocomplete/Autocomplete.vue";
import CountryAutocompleteItem from "./partials/CountryAutocompleteItem"

export default{

  props:{
    value: [Object, String],
    title: String,
    country: {
      type: [Object, String],
      default: () => {return {}}
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  components:{Autocomplete},
  data(){
    return{
      cities: [],
      cityTextState: '',
      template: CountryAutocompleteItem,
      isFetchingCities: false,
      timer: null,
      searchId: 0,
    }
  },
  watch:{
    country(){
      this.clearResults();
    }
  },
  created(){
    // if we land with an existing city, we want to fetch it
    // in order to also derive the timezone.
    // This is super important for chart updates to function properly.
    if(this.value){
      this.searchCities(this.value.name).then(() => {
        const matchingCity = this.cities.find(x => x.name.toLowerCase().trim() === this.value.name.toLowerCase().trim());
        this.citySelected(matchingCity || this.cities[0])
      })
    }
  },

  computed:{
    hasCityError(){
      return this.country && this.cityTextState.length > 0 && !this.value
    }
  },

  methods:{
    getLabel(item){
      return item ? item.name: '';
    },
    citySelected(city){
      this.$emit('input', city);
    },
    updateCities(text) {
      this.cityTextState = text;
      // if city is empty, we don't search. If country is empty, we again do not search
      // no reason to spam back-end with empty requests.
      if(!text || text === ''){
        this.clearResults();
        return;
      }
      // split the search if it includes the state name so we only search
      // by city name. Example: New York City (New York) -> New York City (search string)
      // debounce searching
      clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        this.searchId++;
        this.searchCities(text, this.searchId);
      }, 400);

    },
    clearResults(){
      this.cities = [];
      this.$emit('input', null);
    },
    searchCities(search, id){
      if(!this.country || !this.country.id) return;
      if(!search) return;
      const fixedSearch = search.split(" (")[0];

      const useV2 = process.env.VUE_APP_USE_CITIES_V2 === 'true';

      return this.axios.get(
          process.env.VUE_APP_CITIES_URL + (useV2 ? '/v2' : ''),
          {params: {country: this.country.id, search: fixedSearch}}
      ).then(res => {
          if (!!id && id < this.searchId) return;
          if (useV2) {
            this.cities = res.data;
            return;
          }
          let cities = [];
          for(let key of Object.keys(res.data)){
            for(let city of res.data[key]){
              cities.push({name: city, timezone: key});
            }
          }
          this.cities = cities;
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },
  }
}
</script>