<template>
  <div>
    <center-header-nav v-if="!hideHeader" :center="activeCenter"
                       :prev="prev" :next="next"
                       :center-id="activeCenter.id"
                       :subtitle="$t('hd.centers.dynamics.' + activeCenter?.dynamics?.type)"
                       @changeCenter="val => $emit('changeCenter', val)" />

    <div v-for="item in [...typeIntroduction, ...(content || [])]" :key="item.id" class="mb-1">
      <mbg-accordion-item v-if="item.__component === 'dropzone.dynamic-text'"
                          :text="item.title"
                          :content="item.content"
                          :reverse-background="reverseBackground"
                          html-title />

      <dynamic-zone-renderer v-else :components="item" :add-paddings="false" />
    </div>
  </div>
</template>

<script>
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";
import MbgAccordionItem from "@/components/elements/MbgAccordionItem.vue";
import CenterHeaderNav from "@/components/charts/analysis-connection/partials/CenterHeaderNav.vue";

function splitAtFirstH2(str) {
  const index = str.indexOf("</h2>");
  if (index === -1) return [str, ""]; // If not found, return the whole string as the first part

  return [str.substring(0, index + 5), str.substring(index + 5)]; // Include the "</h2>" in the first part
}

export default {
  props: {
    activeCenter: Object,
    introduction: Object,
    content: Array,
    prev: Object,
    next: Object,
    hideHeader: Boolean,
    reverseBackground: Boolean,
  },
  components: {CenterHeaderNav, MbgAccordionItem, DynamicZoneRenderer},

  computed: {
    typeIntroduction() {
      return this.introduction?.components?.map(x => {
        const [title, content] = splitAtFirstH2(x.content);
        return {
          ...x,
          title: title?.replaceAll('<h2>', '').replaceAll('</h2>', ''),
          content,
        };
      }) || [];
    }
  }
}
</script>