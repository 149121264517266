<template>
  <div id="bridges">
    <div v-if="!selected">
      <dynamic-zone-renderer v-if="splitIntro" :components="splitIntro.components"
                             :title="splitIntro.name"
                             :add-paddings="false"
                             is-toggleable is-reverse-color />

      <dynamic-zone-renderer v-if="morePreciseIntro" :components="morePreciseIntro.components" :add-paddings="false"
                             :title="morePreciseIntro.name"
                             is-toggleable is-reverse-color />

      <div v-if="Object.keys(channelsAnalysisContent).length > 0" class="bg-light p-2 px-3 mb-3 casual-border-radius">
        <h5 class="py-2">{{$t('hd.properties.channels')}}</h5>
        <channel-list-item v-for="channel in eligibleChannels"
                           :key="channel.key"
                           :channel="channel" highlights-disabled
                           @selectChannel="id => select('channel', id)"
        />
      </div>

      <div v-if="Object.keys(gatesAnalysisContent).length > 0" class="bg-light p-2 px-3 casual-border-radius">
        <h5 class="py-2">{{$t('hd.properties.gates')}}</h5>
        <gate-list-item v-for="gate in eligibleGates"
                           :key="gate.id"
                        :gate="gate"
                        highlights-disabled
                        @selected="id => select('gate', id)"
        />
      </div>
    </div>

    <div v-else class="mb-3">

      <div class="d-flex align-items-center mb-2 pb-3">
        <b-button size="xs" variant="primary" class="m-0 mr-3 " @click="goBack">
          <span class="icon-arrow-left"></span>
        </b-button>

        <p class="mb-0"> {{selectedContent?.title}}</p>
      </div>

      <dynamic-zone-renderer :components="selectedContent?.components || []" :add-paddings="false" />
    </div>
  </div>
</template>

<script>
import AnalysisQueryMixin from "@/mixins/AnalysisQueryMixin";
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";
import channels from "@/helpers/rave/constants/channels";
import ChannelListItem from "@/components/charts/properties/list/ChannelListItem.vue";
import ChannelsSetupMixin from "@/mixins/ChannelsSetupMixin";
import GateListItem from "@/components/charts/properties/list/GateListItem.vue";
import gates from "@/helpers/rave/constants/gates";

export default {
  components: {GateListItem, ChannelListItem, DynamicZoneRenderer},
  mixins: [AnalysisQueryMixin, ChannelsSetupMixin],

  props: {
    bridges: Object,
  },

  computed: {

    eligibleChannels() {
      return this.channels.filter(x => this.bridges?.bridgingChannels?.includes(x.key.replace('-', '/'))).map( x => ({
        ...x,
        channel: x.id,
        contentKey: `channels.wide.${x.id}`
      }))
    },

    eligibleGates() {
      return gates.filter(x => this.gatesList.includes(x.id)).map(x => ({
        ...x,
        gate: x.id,
        name: this.$t('hd.gates.' + x.id + '.name'),
        keynote: this.$t('hd.gates.' + x.id + '.titleKeynote'),
        contentKey: `gates.${this.isWideSplit ? 'wide' : 'simple'}.${x.id}`
      }))
    },

    selectedContent() {
      if (!this.selected) return null;
      return this.selected.type === 'channel' ? this.channelsAnalysisContent[this.selected.id]
          :
          this.gatesAnalysisContent[this.selected.id];
    }
  },

  data() {
    return {
      isWideSplit: false,
      splitIntro: null,
      morePreciseIntro: null,
      channelsAnalysisContent: {},
      gatesAnalysisContent: {},
      channels: [],

      selected: null,

      channelList: [],
      gatesList: [],
    }
  },

  created() {
    // if we have channels or wide split gates, we are in a wide split chart.
    if (this.bridges?.bridgingChannels || this.bridges?.bridgingFarGates) this.isWideSplit = true;
    this.channels = this.formattedChannels();

    this.getContent('splitIntro', '/analysis/introductions/introduction.bridges');
    if (this.isWideSplit) {
      this.getContent('morePreciseIntro', '/analysis/introductions/introduction.bridges-wide');
    } else {
      this.getContent('morePreciseIntro', '/analysis/introductions/introduction.bridges-simple');
    }

    this.channelList = this.bridges?.bridgingChannels?.map(channel => channels.find(c => c.key === channel.replace('/', '-'))) || [];
    this.gatesList = this.bridges?.bridgingFarGates || this.bridges?.bridgingGates || [];

    this.getData();
  },

  methods: {

    getData() {
      const channelKeys = this.channelList.map(c => `channels.wide.${c.id}`);
      const gateKeys = this.gatesList.map(g => `gates.${this.isWideSplit ? 'wide' : 'simple'}.${g}`);

      this.bulkGetContent('channelsAnalysisContent', 'bridges', channelKeys);
      this.bulkGetContent('gatesAnalysisContent', 'bridges', gateKeys);
    },

    select(type, id) {
      this.$emit('hasSelectedItem', true);
      const contentKey = type === 'channel' ? `channels.wide.${id}` : `gates.${this.isWideSplit ? 'wide' : 'simple'}.${id}`;
      this.selected = {type, id: contentKey};
    },

    goBack() {
      this.$emit('hasSelectedItem', false);
      this.selected = null;
    }

  }
}
</script>