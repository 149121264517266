var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-0 px-md-1",attrs:{"id":"related-links"}},[(_vm.getPlanetaryActivations.length > 0)?_c('div',{staticClass:"planets"},[_c('p',{staticClass:"my-2 d-flex align-items-center"},[_vm._v(_vm._s(_vm.$t('charts.properties.planetary')))]),_c('div',{staticClass:"grid grid-cols-1 gap-5"},_vm._l((_vm.getPlanetaryActivations),function(planet){return _c('related-link-toggle',{key:`planet-${planet.id}-${planet.activation}`,attrs:{"type":"planet","is-transit":_vm.isTransitPlanet(planet),"start-open":"","depth":_vm.depth,"is-reverse-background":_vm.isNested,"render-props":{
                               id: planet.id,
                               planet,
                               planetId: planet.id,
                               activation: planet.activation,
                               chartId: planet.chartId,
                               getIcon: () => {},
                               customIconClasses: _vm.getPlanetColorClasses(planet),
                               reverseBackground: !_vm.isNested,
                               isGateChild: true
                            },"label":_vm.getPlanetText(planet)}})}),1)]):_vm._e(),(!_vm.hideCenter)?_c('div',{staticClass:"center"},[_c('p',{staticClass:"my-2 d-flex flex-wrap align-items-center"},[_vm._v(_vm._s(_vm.$t('hd.objects.center')))]),_c('div',{staticClass:"grid grid-cols-1 gap-5"},[_c('related-link-toggle',{key:`center-${_vm.getCenter.id}`,attrs:{"type":"center","render-props":{
                                id: _vm.getCenter.id,
                                centerId: _vm.getCenter.id,
                                state: _vm.getCenter.state,
                                reverseBackground: !_vm.isNested,
                                chart: _vm.chart,
                           },"depth":_vm.depth,"is-nested":_vm.isNested,"is-reverse-background":_vm.isNested,"label":`<span class='text-md icon-center-${_vm.getCenter.icon}'></span>`,"text":_vm.getCenter.state + ' ' + _vm.$t('hd.centers.'+ _vm.getCenter.id + '.name') + ' ' + _vm.$t('hd.objects.center')}})],1)]):_vm._e(),(_vm.isChannelDefined)?_c('div',{staticClass:"channel"},[_c('p',{staticClass:"my-2 d-flex align-items-center"},[_vm._v(_vm._s(_vm.$t('hd.properties.channels')))]),_c('div',{staticClass:"grid grid-cols-1 gap-5"},_vm._l(([_vm.getChannel]),function(channel){return _c('related-link-toggle',{key:`channel-${channel.id}`,attrs:{"icon":"icon-channels","type":"channel","item":{
                              type: 'channel',
                              id: channel.id,
                           },"depth":_vm.depth,"is-nested":_vm.isNested,"is-reverse-background":_vm.isNested,"render-props":{
                              id: channel.id,
                              channelId: channel.id,
                              reverseBackground: !_vm.isNested,
                              chart: _vm.chart,
                           },"text":_vm.$t('hd.channels.'+ channel.id + '.name')}})}),1)]):_vm._e(),(_vm.isChannelDefined)?_c('div',{staticClass:"harmonic-gate"},[_c('p',{staticClass:"my-2 d-flex align-items-center"},[_vm._v(_vm._s(_vm.$t('charts.properties.harmonicGate')))]),_c('div',{staticClass:"grid grid-cols-1 gap-5"},_vm._l((_vm.getHarmonicGates),function(gate){return _c('related-link-toggle',{key:`gate-${gate}`,attrs:{"type":"gate","render-props":{
                                id: gate,
                                gateId: gate,
                                reverseBackground: !_vm.isNested,
                                chart: _vm.chart,
                            },"depth":_vm.depth + 1,"is-nested":_vm.isNested,"is-reverse-background":_vm.isNested,"label":_vm.getGateToggleLabel(gate)}})}),1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }