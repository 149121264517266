<template>
  <div v-if="isConnectionChart(chart) && center && (centersContent[centerId]?.length > 0 || intro?.length > 0)"
       class="pt-1 pb-1 px-md-1">
    <p class="my-2 d-flex align-items-center">{{ $t('pages.home.understandCta.buttons.connection.title') }}</p>

    <center-dynamic-single :content="centersContent[centerId]  || []"
                           :active-center="center"
                           :introduction="intro ?? []"
                           :reverse-background="reverseBackground"
                           hide-header
    />
  </div>
</template>
<script>
import PartnershipAnalysis from "@/mixins/PartnershipAnalysis";
import CenterMixin from "@/mixins/CenterMixin";
import CenterDynamicSingle from "@/components/charts/analysis-connection/partials/CenterDynamicSingle.vue";

export default {
  components: {CenterDynamicSingle},
  props: {
    chart: Object,
    centerId: [String, Number],
    reverseBackground: Boolean,
  },
  mixins: [PartnershipAnalysis, CenterMixin],
  data() {
    return {
      center: null,
      centersContent: {},
      intro: {},
    }
  },
  created() {
    this.setup();
  },

  methods: {
    setup() {
      this.setupCenters();
      this.determineCenterDynamics();

      const center = this.centers.find(x => x.id === Number(this.centerId));
      this.center = center;
      this.getCenterDynamicContent('centersContent', center);

      this.getContent('intro', this.getCenterDynamicIntros[center.dynamics?.type]);
    }
  }
}
</script>