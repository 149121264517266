<template>
  <div class="px-4">
    <dynamic-zone-renderer v-if="content" :components="content.components" :add-paddings="false" />
    <div>
      <div v-for="channel in getDominanceChannels" :key="channel.channel">
        <name-linking-dynamic-zone-renderer
            v-if="channel && channelsContent[channel.channel]"
            :title="channel.name"
            :channel="channel"
            :components="channelsContent[channel.channel].components"
            :type="`${$t('charts.analysis.connection.activation.dominance')} (${getFirstName(channel.dominant)} >> ${getFirstName(channel.dominee)})`"
            use-reverse-color
        />
      </div>
      <div v-if="getDominanceChannels.length === 0 && !fetching" class="font-500">
        There are no dominance connections in this chart.
      </div>

      <slot name="unlock-prompt"/>
      <ProgressChecker class="pt-3" type="companionship" id="3" :has-prev="hasPrev" :has-next="hasNext"
                       :order="order"
                       @prevSection="$emit('prevSection')"
                       @nextSection="$emit('nextSection')"/>
    </div>
  </div>

</template>

<script>

import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer";
import {mapState} from "vuex";
import NameLinkingDynamicZoneRenderer from "../../renderer/NameLinkingDynamicZoneRenderer.vue";
import ProgressChecker from "@/components/charts/analysis/progress/ProgressChecker.vue";
import PartnershipAnalysis from "@/mixins/PartnershipAnalysis";

export default{
  mixins: [PartnershipAnalysis],
  components: {ProgressChecker, NameLinkingDynamicZoneRenderer, DynamicZoneRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      content: null,
      channelsContent: {}
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
  },
  created(){
    this.getIntroduction('dominance');
    for (let channel of this.getDominanceChannels) {
      this.getContentForChannel('dominance', 'channelsContent', channel.channel);
    }
  },
}
</script>