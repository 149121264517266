var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-0 px-md-1",attrs:{"id":"related-links"}},[_c('div',{staticClass:"center"},[_c('p',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.$t('hd.properties.centers')))]),_c('div',{staticClass:"grid grid-cols-1 gap-5"},_vm._l((_vm.getCenters),function(center){return _c('related-link-toggle',{key:`center-${center.id}`,attrs:{"type":"center","render-props":{
                                id: center.id,
                                centerId: center.id,
                                state: center.state,
                                reverseBackground: !_vm.isNested,
                                chart: _vm.chart
                           },"depth":_vm.depth,"is-nested":_vm.isNested,"is-reverse-background":_vm.isNested,"label":`<span class='text-md icon-center-${center.icon}'></span>`,"text":center.state + ' ' + _vm.$t('hd.centers.'+ center.id + '.name') + ' ' + _vm.$t('hd.objects.center')}})}),1)]),_c('div',{staticClass:"channel"},[_c('p',{staticClass:"my-2"},[_vm._v(_vm._s(_vm.$t('hd.gates.name.many')))]),_c('div',{staticClass:"grid grid-cols-1 gap-5"},_vm._l((_vm.getGates),function(gate){return _c('related-link-toggle',{key:`gate-${gate}`,attrs:{"type":"gate","render-props":{
                              id: gate,
                              gateId: gate,
                              reverseBackground: !_vm.isNested,
                              chart: _vm.chart,
                            },"depth":_vm.depth,"is-nested":_vm.isNested,"is-reverse-background":_vm.isNested,"label":_vm.getGateToggleLabel(gate)}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }