<template>

  <div>
    <dynamic-zone-renderer v-if="definitionIntro" :title="definitionIntro.name" :components="definitionIntro.components" is-toggleable
                           :add-paddings="false" class="mb-4" is-reverse-color/>
    <dynamic-zone-renderer v-if="definition" :components="definition.components" :title="definition.name" :add-paddings="false" />

  </div>
</template>

<script>

import AnalysisQueryMixin from "../../../../../mixins/AnalysisQueryMixin";
import DynamicZoneRenderer from "../../../../renderer/DynamicZoneRenderer.vue";

export default {
  components: {DynamicZoneRenderer},

  props:{
    chart: Object
  },

  mixins: [AnalysisQueryMixin],

  data(){
    return {
      definition: null,
      definitionIntro: null,
    }
  },

  created(){
    this.getDefinition();
  },

  methods:{
    getDefinition(){
      let url = '/definitions/' +  this.chart.chart.definition
      this.getContent('definition', url);
      this.getContent('definitionIntro', '/analysis/introductions/introduction.definition')
    },
  }
}
</script>