<template>
  <div v-if="isConnectionChart(chart) && channel" class="px-md-1">
    <p class="my-2 d-flex align-items-center">{{ $t('pages.home.understandCta.buttons.connection.title') }}</p>

      <!-- Dominance -->
      <name-linking-dynamic-zone-renderer
          v-if="channel && singleAnalysisContent.dominance"
          :title="`${$t('properties.dominance')}: <span class='font-weight-normal'>(${getFirstName(channel.dominant)} >> ${getFirstName(channel.dominee)})</span>`"
          :channel="channel"
          :components="singleAnalysisContent.dominance.components"
          :planets="chart?.chart?.planets || []"
          :names="[chart.meta.person1.name, chart.meta.person2.name]"
          is-compromise
          use-reverse-color
          :use-reverse-background="reverseBackground"
      />

    <!-- Electromagnetic content -->
    <name-linking-dynamic-zone-renderer
        v-if="channel && singleAnalysisContent.electromagnetic"
        :title="`${$t('properties.electromagnetic')}: <span class='font-weight-normal'>${channel.name}</span>`"
        :channel="channel"
        :components="singleAnalysisContent.electromagnetic.components"
        :planets="chart?.chart?.planets || []"
        :names="[chart.meta.person1.name, chart.meta.person2.name]"
        use-reverse-color
        :use-reverse-background="reverseBackground"
    />

    <!--  compromise  -->
    <name-linking-dynamic-zone-renderer
        v-if="channel && singleAnalysisContent.compromise"
        :channel="channel"
        :components="singleAnalysisContent.compromise.components"
        :title="`${$t('properties.compromise')}: <span class='font-weight-normal'>(${getFirstName(channel.dominant)} >> ${getFirstName(channel.dominee)})</span>`"
        :planets="chart?.chart?.planets || []"
        :names="[chart.meta.person1.name, chart.meta.person2.name]"
        is-compromise
        use-reverse-color
        :use-reverse-background="reverseBackground"
    />

    <!-- Companionship -->
    <name-linking-dynamic-zone-renderer
        v-if="channel && singleAnalysisContent.companionship"
        :channel="channel"
        :components="singleAnalysisContent.companionship.components"
        :title="`${$t('properties.companionship')}: <span class='font-weight-normal'>${channel.name}</span>`"
        :planets="chart?.chart?.planets || []"
        :names="[chart.meta.person1.name, chart.meta.person2.name]"
        is-compromise
        use-reverse-color
        :use-reverse-background="reverseBackground"
        disabled
    />
  </div>
</template>
<script>
import PartnershipAnalysis from "@/mixins/PartnershipAnalysis";
import NameLinkingDynamicZoneRenderer from "@/components/renderer/NameLinkingDynamicZoneRenderer.vue";

export default {
  components: {NameLinkingDynamicZoneRenderer},
  props: {
    chart: Object,
    channelId: [String, Number],
    gateId: [String, Number],
    reverseBackground: Boolean,
  },
  mixins: [PartnershipAnalysis],
  data() {
    return {
      channel: null,
    }
  },
  created() {
    const channel = this.channels.find(x => +x.channel === +this.channelId);
    if (channel) this.channel = channel;

    const channelFromGate = this.channels.find(x => x.gates.includes(+this.gateId));
    if (channelFromGate) this.channel = channelFromGate;

    if (this.channel) this.getForChannel(this.channel);
  }
}
</script>