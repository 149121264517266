<template>
  <div class="px-4">
    <dynamic-zone-renderer v-if="content" :components="content.components" :add-paddings="false"></dynamic-zone-renderer>
    <div>
      <div v-for="channel in getCompanionshipChannels" :key="channel.channel">

        <name-linking-dynamic-zone-renderer
            v-if="channel && channelsContent[channel.channel]"
            :channel="channel"
            :components="channelsContent[channel.channel].components"
            :title="channel.name"
            :planets="chart?.chart?.planets || []"
            :names="[chart.meta.person1.name, chart.meta.person2.name]"
            is-compromise
            use-reverse-color
            disabled
        />
      </div>
      <div v-if="getCompanionshipChannels.length === 0 && !fetching" class="font-500">
        There are no companionship connections in this chart.
      </div>
      <slot name="unlock-prompt"/>
      <ProgressChecker class="pt-2" type="companionship" id="5" :has-prev="hasPrev" :has-next="hasNext"
                       :order="order"
                       @prevSection="$emit('prevSection')"
                       @nextSection="$emit('nextSection')"/>
    </div>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer";
import {mapState} from "vuex";
import ProgressChecker from "@/components/charts/analysis/progress/ProgressChecker.vue";
import PartnershipAnalysis from "@/mixins/PartnershipAnalysis";
import NameLinkingDynamicZoneRenderer from "@/components/renderer/NameLinkingDynamicZoneRenderer.vue";

export default{
  mixins: [PartnershipAnalysis],
  components: {NameLinkingDynamicZoneRenderer, ProgressChecker, DynamicZoneRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      content: null,
      channelsContent: {}
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
  },
  created(){
    this.getIntroduction('companionship');
    for (const channel of this.getCompanionshipChannels) {
      this.getContentForChannel('companionship', 'channelsContent', channel.channel);
    }
  },
}
</script>