<template>
  <div class="channel">
    <b-overlay :show="loading" rounded="sm">
      <b-card v-if="!isRelatedToggle" class="bg-gray shadow-none text-center py-3 my-3 ">
        <div class="row mb-3 prev-next">
          <div class="col-6">

            <div v-if="prev" class="d-flex align-items-center cursor-pointer" style="opacity:0.7;"
                 @click="$emit('changeChannel', prev.channel)">
              <svg fill="none" height="14" style="transform:rotate(180deg)" viewBox="0 0 8 14" width="8"
                   xmlns="http://www.w3.org/2000/svg">
                <path :stroke="darkMode ? '#fff' : '#191c23'" d="M1 13L7 7L0.999999 1" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5"/>
              </svg>
              <span class="ml-2 text-sm">
               {{ $t('hd.channels.' + prev.channel + '.name') }}
            </span>

            </div>

          </div>
          <div v-if="next" class="col-6 text-right" @click="$emit('changeChannel', next.channel)">

            <div v-if="next" class="d-flex align-items-center ml-auto justify-content-end cursor-pointer"
                 style="opacity:0.7;">
              <span class="mr-2 text-sm">
                    {{ $t('hd.channels.' + next.channel + '.name') }}
              </span>
              <svg fill="none" height="14" viewBox="0 0 8 14" width="8" xmlns="http://www.w3.org/2000/svg">
                <path :stroke="darkMode ? '#fff' : '#191c23'" d="M1 13L7 7L0.999999 1" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5"/>
              </svg>
            </div>

          </div>
        </div>
        <span class="icon-channels" style="font-size:2.5rem"></span>
        <h3 class="font-weight-bolder">{{ $t('hd.channels.' + channelId + '.name') }}</h3>
        <h5 v-if="subtitle" class="font-weight-normal">{{ subtitle }}</h5>
      </b-card>

      <channel-content :channel-id="channelId"
                       :loading="loading"
                       @keynoteSet="keynote => this.subtitle = keynote"
                       :reverse-background="reverseBackground"
                       :is-related-toggle="!!isRelatedToggle"
                       @loading="val => this.loading = val"
      />

      <div class="pt-2">
        <single-channel-partnership :channel-id="channelId" :chart="chart" :reverse-background="reverseBackground"/>

        <channel-related-links v-if="showRelatedLinks" :selected-id="channelId"
                               :depth="depth"
                               :is-nested="!!isRelatedToggle"/>
      </div>

    </b-overlay>
  </div>
</template>
<script>
import ChannelContent from "../content/ChannelContent";
import ChannelRelatedLinks from "../related-links/ChannelRelatedLinks";
import SingleChannelPartnership from "@/components/charts/analysis-connection/partials/SingleChannelPartnership.vue";

export default {
  components: {SingleChannelPartnership, ChannelRelatedLinks, ChannelContent},
  props: {
    chart: Object,
    isRelatedToggle: {
      type: Boolean,
      default: false,
    },
    depth: Number,
    channelId: Number,
    prev: Object,
    next: Object,
    showRelatedLinks: {
      type: Boolean,
      default: true
    },
    reverseBackground: Boolean
  },
  data() {
    return {
      loading: false,
      subtitle: null
    }
  }
}
</script>