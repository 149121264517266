<template>
  <div id="centers" class="unselectable" :class="showAsCard ? 'card' :''">
    <div :class="showAsCard ? 'card-body' : ''">
      <div v-if="showTab" class="d-flex align-items-center py-2">
        <div class=" center-tab d-flex align-items-center cursor-pointer"
             :class="activeType === 'defined' ? 'current' : ''" @click="activeType = 'defined';selectedCenter = null;">
          {{ $t('hd.constants.centerDefinitionStates.2') }} {{ $t('hd.properties.centers') }}
        </div>
        <div class="center-tab d-flex align-items-center mr-3 cursor-pointer"
             :class="activeType === 'open' ? 'current' : ''"
             @click="activeType = 'open';selectedCenter = null;">
          {{ $t('hd.constants.centerDefinitionStates.1') }} {{ $t('hd.properties.centers') }}
        </div>

        <div v-if="isConnectionChart(chart)" class="center-tab d-flex align-items-center mr-3 cursor-pointer"
             :class="isConnectionTab ? 'current' : ''"
             @click="activeType = 'connection';selectedCenter = null;"
        >
          {{ $t('charts.analysis.connection.tabs.1') }}
        </div>

      </div>
      <div v-if="!isConnectionTab" class="d-flex align-items-center">
        <b-button v-if="showSingleCenter" size="xs" variant="primary" class="m-0 mr-3 " @click="goBack">
          <span class="icon-arrow-left"></span>
        </b-button>
        <mbg-text-input v-model="search" :show-label="false"
                        :title="$t('header.menu.search') + ' ' + $t('hd.properties.centers')"  icon="icon-search" class="mb-0 w-100"/>


      </div>
      <!--  Renders gate itself  -->

      <div v-if="showSingleCenter">
        <Center :key="contentKey"
                :prev="getPrevCenter" :next="getNextCenter" :center-id="selectedCenter"
                :type="activeType"
                :center="getCenter"
                :show-related-links="showAsCard"
                @changeCenter="center => selectCenter(center)"
        />
        <unlock-content-prompt v-if="!isPropertiesUnlocked && !disableUrlNavigation" :chart-id="chart.id" :category="getUnlockCategory"/>
      </div>


    <!--   Renders list of centers! -->
      <div v-else-if="!isConnectionTab">
        <no-content v-if="getCenters.length === 0" :text="searching ? undefined : (activeType === 'open' ? 'There are no open Centers' : 'There are no defined Centers')"/>
        <center-item-selector v-for="center in getCenters"
                              :key="center.id"
                              :center="center"
                              @highlightElement="(cntr) => highlightElement({
                                type: types.center,
                                id: cntr.highlightElem,
                                includePlanets: true,
                                isConnectionChart: isConnectionChart(chart),
                                chartPlanets: chart.chart.planets,
                              })"
                              @clearAllHighlights="clearAllHighlights"
                              @select="selectCenter"
                              :label="activeType === 'open' ? $t('hd.constants.centerDefinitionStates.1') :  $t('hd.constants.centerDefinitionStates.2')"
        />
      </div>

      <div v-else-if="isConnectionTab" class="py-3">
        <ConnectionTheme style="padding:0!important" hide-progress>
          <template v-if="!isUnlocked" #unlock-prompt>
            <unlock-content-prompt v-if="!isUnlocked" class="mt-4" category="companionship" :chart-id="$store.state.charts.chart.id" />
          </template>
        </ConnectionTheme>
      </div>

    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import MbgTextInput from "../../elements/MbgTextInput";
import centerConstants from "../../../helpers/rave/constants/centers";
import Center from "./singles/Center";
import util from "../../../mixins/util";
import HighlightingMixin from "../../../mixins/HighlightingMixin";
import NoContent from "../../structure/NoContent.vue";
import UnlockContentPrompt from "../../billing/UnlockContentPrompt.vue";
import ChartUtil from "../../../mixins/ChartUtil";
import CenterItemSelector from "@/components/charts/analysis-connection/partials/CenterItemSelector.vue";
import CenterMixin from "@/mixins/CenterMixin";
import ConnectionTheme from "@/components/charts/analysis-connection/connection-theme.vue";
export default{

  components:{ConnectionTheme, CenterItemSelector, UnlockContentPrompt, NoContent, Center, MbgTextInput},
  props:{
    showAsCard: {
      type: Boolean,
      default: true
    },
    showTab: {
      type:Boolean,
      default: true
    },
    type: {
      type: String,
      default: null
    },
    // This is "true" when we're on "analysis" but not on properties.
    // This disables URL navigation, and also hides the double "unlock" button.
    disableUrlNavigation: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [util, HighlightingMixin, ChartUtil, CenterMixin],

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),

    isUnlocked(){
      return this.$store.state.charts.chartStatus.is_unlocked
    },

    showSingleCenter(){
      return this.selectedCenter !== null && this.selectedCenter >= 0 && !this.searching;
    },

    isConnectionTab() {
      return this.activeType === 'connection';
    },

    filterCenters(){
      if (this.activeType === 'connection') return [];
      if(this.activeType === 'defined'){
        return this.centers.filter(x => x.state === centerConstants.centerDefinitionStates.DEFINED);
      }
      // we return open centers as one
      return this.centers.filter(x => x.state !== centerConstants.centerDefinitionStates.DEFINED);
    },

    getCenters(){
      if(this.search) return this.filterCenters.filter(x =>
          (x.id.toString().includes(this.search) ||
          x.name.toString().toLowerCase().includes(this.search.toLowerCase()))
      )

      return this.filterCenters;
    },

    getCenter(){
      return this.filterCenters[this.filterCenters.findIndex(x => x.id === this.selectedCenter)]
    },

    getPrevCenter(){
      return this.filterCenters[this.filterCenters.findIndex(x => x.id === this.selectedCenter)-1];
    },

    getNextCenter(){

      return this.filterCenters[this.filterCenters.findIndex(x => x.id === this.selectedCenter)+1];
    }
  },
  watch:{
    search(val){
      if(val && val !== '') this.searching = true;
      else this.searching = false;
    },
    activeType(val){
      if(this.disableUrlNavigation) return;
      this.urlQuery.set('state', val);
      this.fixUrlQuery('?' + this.urlQuery.toString());
      // this.selectedCenter = null; // de-select center
    },
    selectedCenter(val){
      this.$emit('hasSelectedItem', !isNaN(parseInt(val)));
      if(this.disableUrlNavigation) return;
      if(val != null) this.urlQuery.set('selected', val);
      else this.urlQuery.delete('selected');
      this.fixUrlQuery('?' + this.urlQuery.toString());
    }
  },


  data(){
    return{
      selectedCenter: null,
      search: null,
      searching: false,
      urlQuery: null,
      activeType: 'defined',
      contentKey: 0
    }
  },

  created(){

    this.urlQuery = this.getQuery()
    this.setupCenters();
    this.fixActiveCenter();
    if(this.type) this.activeType = this.type;
  },

  methods:{
    fixActiveCenter(){
      this.selectedCenter = isNaN(parseInt(this.urlQuery.get('selected'))) ? null : parseInt(this.urlQuery.get('selected'));

      // if we have an active center, the active tab is determined by the state of it.
      // Otherwise, we are looking at the query params.
      if(this.selectedCenter !== null && this.selectedCenter !== undefined){
        if(this.chart.chart.centers[this.selectedCenter] !== centerConstants.centerDefinitionStates.DEFINED) this.activeType = 'open';
        else this.activeType = 'defined';
      }else{
       this.activeType = this.urlQuery.get('state') || 'defined'
      }
    },

    selectCenter(id){
      this.searching = false;
      this.selectedCenter = id;
      this.contentKey++;
      this.clearAllHighlights();
    },

    goBack(){
      this.selectedCenter = null;
    },
  }
}
</script>
<style lang="scss">
#centers{
  .small-circle{
    border-radius:100%;
    width:9px;
    height:9px;
  }
  .center-tab{
    padding:5px;
    border-bottom:3px solid;
    border-color:transparent;
    border-radius:3px;
    margin-right:10px;
    &.current{
      font-weight:bold;
      border-color:$primary;
    }
  }
}

</style>