import Vue from "vue"
import VueRouter from "vue-router";
import Account from "./views/Account/Account";
import NewChart from "./views/NewChart";
import SingleChart from "./views/Charts/SingleChart";
import Library from "./views/Charts/Library";
import Checkout from "./views/Checkout/Checkout";
import {getLocalStorageItem, localStorageKeys} from "./utils/localStorage";
import PrintRoom from "./views/PrintRoom.vue";
import PageNotFound from "./components/global/PageNotFound.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import("./views/Home"),
        meta: {requiresAuth: true, title: 'Dashboard' }
    },
    {
        path: '/login',
        name: 'login',
        component:  () => import('./views/Auth/Login'),
        meta:{ authPage: true, layout: 'auth', title: 'Login' }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('./views/Auth/Register'),
        meta:{ authPage: true, layout:'auth', title: 'Register' }
    },
    {
        path: '/create-account-chart',
        name: 'create-account-chart',
        component: () => import('./views/Auth/CreateAccountChart.vue'),
        meta: {
            layout: 'auth', requiresAuth: true, requiresChartToken: true,
            isChartCreationPage: true,
            title: 'Create Account Chart'
        }

    },
    {path: '/account', name: 'account', component: Account,
        meta: { requiresAuth: true, title: 'Account' }
    },
    {path: '/checkout', name: 'checkout', component: Checkout,
        meta: { requiresAuth: true, title: 'Checkout' }
    },
    {path: '/new-chart', name: 'new-chart', component: NewChart,
        meta: { requiresAuth: true, title: 'New Chart' }
    },
    {path: '/library', name: 'library', component: Library,
        meta: { requiresAuth: true, requiresChartToken: true, title: 'Library' }
    },

    // Personal chart.
    {path: '/charts/user', name: 'user-chart', component: SingleChart,
        meta: { requiresAuth: true, requiresChartToken: true, title: 'User Personal Chart' }
    },
    {path: '/charts/:chartId', name: 'single-chart', component: SingleChart,
        meta: { requiresAuth: true, requiresChartToken: true, title: 'Single Chart' }
    },
    // TODO: Consider blocking this route from apache route rules.
    {
      path: '/private/print-room', name: 'print-room', component: PrintRoom
    },
    {
        path: '/charts/transit/:transit/',
        name: 'temp-transit-chart',
        component: SingleChart,
        meta: { requiresAuth: true, requiresChartToken: true, title: 'Temporary Transit Chart' }
    },
    {
        path: '/charts/transit/:transit/:transitChartId',
        name: 'temp-transit-connection-chart',
        component: SingleChart,
        meta: { requiresAuth: true, requiresChartToken: true, title: 'Temporary Personal Transit Chart' }
    },
    {path: '/public/jovian', name: 'jovian-shared-chart',
        component: () => import('./views/Public/SharedChart.vue'),
        meta: { layout: 'auth', title: 'Jovian Shared Chart' }
    },
    {
      path: '/free-chart',
        name: 'public-free-chart',
        component: () => import('./views/Public/SharedChart.vue'),
        meta: { layout: 'auth', title: 'Create Free Chart' }
    },
    {path: '/public/:chartId', name: 'shared-chart',
        component: () => import('./views/Public/SharedChart.vue'),
        meta: { layout: 'auth', title: 'Shared Chart' }
    },

    // 404
    {
        path: "*", component: PageNotFound,
        meta: {layout: 'error', title: 'Error Page'}
    }



];

const router = new VueRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {

    if(!to.query.owl) window.scrollTo(0,0);

    if(to.query.activation_link){
        localStorage.setItem(localStorageKeys.ACTIVATE_ACCOUNT, to.query.activation_link);
    }

    if(document) document.title = to.meta.title;

    // handle impersonation login.
    if(to.query.impersonate) {
        const userData = JSON.parse(to.query.user);
        const data = {
            ...to.query,
            user: userData,
            token: userData.token,
            adminToken: window.location.hash
        }
        sessionStorage.setItem(localStorageKeys.IMPERSONATION, JSON.stringify(data));
    }

    const isLoggedIn = !!getLocalStorageItem(localStorageKeys.LOGGED_IN);
    const chartId = !!getLocalStorageItem(localStorageKeys.CHART_ID);

    // handle fresh account without a set chart. We want to prompt a user to create the chart.
    if(to.meta.requiresAuth && isLoggedIn && !to.meta.isChartCreationPage && !chartId){
        next({
            name: 'create-account-chart'
        });
        return;
    }

    // Disable the new user chart creation page for users that own a chart.
    if(chartId && to.meta.isChartCreationPage){
        next({
            name: 'home'
        });
        return;
    }

    if(to.meta.authPage && isLoggedIn) {
        next({name: 'home'});
        return;
    }

    if(to.meta.requiresAuth && !isLoggedIn){
        next({name: 'login', query: {
            redirect: to.fullPath
        }});
        return;
    }
    next();
});

router.afterEach((to) => {
    if (typeof window.gtag === 'function' && !to.path.includes('/private')) {
        window.gtag('config', 'G-PRTDD1B56P', { 'page_path': to.fullPath });
    }

    if (window && window.fbq && typeof window.fbq === 'function' && !to.path.includes('/private')) {
        window.fbq('init', '2258319874506819');
        window.fbq('track', 'PageView');
    }
})

export default router;