<template>
  <div class="px-4">
    <dynamic-zone-renderer v-if="intro" :components="intro.components"
                           :title="intro.name"
                           :add-paddings="false" is-toggleable is-reverse-color />
    <div v-if="selectedCenter !== null">
      <b-button size="sm" variant="primary" class="back-btn" @click="selectedCenter = null">
        <span class="icon-arrow-left" />
      </b-button>

      <center-dynamic-single :content="centersContent[selectedCenter]" :active-center="activeCenter"
                             :prev="prev" :next="next"
                             :introduction="perTypeIntros[activeCenter.dynamics?.type]"
                             @changeCenter="selectCenter"
      />

    </div>
    <div v-else>
      <center-item-selector v-for="center in centers"
                            :key="center.id"
                            :center="center"
                            @highlightElement="(ctr) => highlightElement({
                              type: types.center,
                              id: ctr.highlightElem,
                              includePlanets: true,
                              chartPlanets: chart.chart.planets,
                              isConnectionChart: true,
                            })"
                            @clearAllHighlights="clearAllHighlights"
                            @select="selectCenter"
                            :label="$t('hd.centers.dynamics.' + center?.dynamics?.type)"
      />
    </div>
    <div>
      <slot name="unlock-prompt"/>
      <ProgressChecker class="pt-3" type="companionship" id="8" :has-prev="hasPrev" :has-next="hasNext"
                       :order="order"
                       @prevSection="$emit('prevSection')"
                       @nextSection="$emit('nextSection')"/>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer";
import {mapState} from "vuex";
import ProgressChecker from "@/components/charts/analysis/progress/ProgressChecker.vue";
import CenterMixin from "@/mixins/CenterMixin";
import HighlightingMixin from "@/mixins/HighlightingMixin";
import CenterItemSelector from "@/components/charts/analysis-connection/partials/CenterItemSelector.vue";
import PartnershipAnalysis from "@/mixins/PartnershipAnalysis";
import CenterDynamicSingle from "@/components/charts/analysis-connection/partials/CenterDynamicSingle.vue";

export default{
  mixins: [PartnershipAnalysis, CenterMixin, HighlightingMixin],
  components: {
    CenterDynamicSingle,CenterItemSelector, ProgressChecker, DynamicZoneRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      intro: null,
      channelsContent: {},
      selectedCenter: null,
      data: null,
      centersContent: {},
      // hold the per type intros (0,1,2,3)
      perTypeIntros: {},
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),

    activeCenter() {
      return this.centers.find(x => x.id === this.selectedCenter);
    },

    prev() {
      if (this.selectedCenter === null) return null;
      const index = this.centers.findIndex(x => x.id === this.selectedCenter);
      if (index === 0) return null;
      return this.centers[index - 1];
    },
    next(){
      if (this.selectedCenter === null) return null;
      const index = this.centers.findIndex(x => x.id === this.selectedCenter);
      if (index === this.centers.length - 1) return null;
      return this.centers[index + 1];
    },
  },
  created() {
    this.getContent('intro', '/analysis/introductions/partnership-analysis.center-dynamics');
    this.setupCenters();
    this.determineCenterDynamics();
    this.getAllContent();
    this.getIntros();
  },

  methods: {
    selectCenter(id) {
      this.selectedCenter = id;
      this.clearAllHighlights();
    },

    getIntros() {
      const intros = this.getCenterDynamicIntros;

      Promise.allSettled(Object.values(intros).map(x => this.getContent('data', x)))
          .then(data => {
            for (let i=0; i< Object.keys(intros).length; i++) {
              if (data[i].status === 'rejected') continue;
              Vue.set(this.perTypeIntros, Object.keys(intros)[i], data[i].value);
            }
          }).catch(()=> {});
    },
    getAllContent() {
      Promise.allSettled(this.centers.map(x => this.getCenterDynamicContent('centersContent', x)));
    }
  }
}
</script>