import {mapState} from "vuex";
import moment from "moment/moment";
import {config} from "@/utils/config";


const DATE_DISPLAY_FORMAT = 'DD MMMM yyyy @ HH:mm';

export default{

  data(){
    return {
      globalConfig: {
        chartsUrl: config.chartsUrl,
      },
      CheckoutTypes: {
        SINGLE: 0,
        SUBSCRIPTION: 1
      }
    }
  },
  computed:{
    ...mapState({
      darkMode: state => state.darkMode
    }),
    getDarkModeText(){
      return this.darkMode ? 'dark' : 'light';
    },
    getDarkModeClass() {
      return this.darkMode ? 'theme--dark' : 'theme--light'
    }
  },
  methods: {
    redirectTo(name, hash, params, query){
      this.fullScreenMenu = false;

      let redirectObject = {name: name}
      if(hash) redirectObject.hash = hash
      if(params) redirectObject.params = params;
      if(query) redirectObject.query = query;

      this.$router.push(redirectObject);
    },
    loadLocalIcon(icon){
      return require('@/assets/icons/'+icon);
    },
    loadLocalImage(image){
      return require('@/assets/images/'+image);
    },
    setBodyScrollBehavior(scroll){
      if(!document) return
      if(scroll) document.body.style.overflow = "auto";
      else document.body.style.overflow = "hidden";
    },
    formatDate(date, isUtc = false, customFormat = undefined){
      return isUtc ? moment.utc(date).format(customFormat || DATE_DISPLAY_FORMAT) : moment(date).format(customFormat || DATE_DISPLAY_FORMAT)
    },

    switchDarkMode(value = undefined){
      const darkModeClass = 'theme--dark';
      const lightModeClass = 'theme--light';

      const respectValue = typeof value !== 'object';
      this.$store.dispatch('setDarkMode', respectValue ? value : !this.darkMode);

      if(this.darkMode){
        document.body.classList.remove(lightModeClass);
        document.body.classList.add(darkModeClass);
      }else{
        document.body.classList.remove(darkModeClass);
        document.body.classList.add(lightModeClass);
      }
    },
    getFirstName(name) {
      if (!name) return;
      if (typeof name !== 'string') return name;

      return name.split(' ')[0];
    }
  }
}