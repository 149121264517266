<template>

  <div id="channels" class="unselectable" :class="showAsCard ? 'card': ''">

    <div :class="showAsCard ? 'card-body': ''">
      <div class="d-flex align-items-center">
        <b-button v-if="isChannelSelected" size="xs" variant="primary" class="m-0 mr-3 " @click="goBack">
          <span class="icon-arrow-left"></span>
        </b-button>

        <mbg-text-input v-model="search" :show-label="false"
                        :title="$t('header.menu.search') + ' ' + $t('hd.objects.channels')"  icon="icon-search" class="mb-0 w-100"/>

      </div>
      <!--  Renders channel itself  -->

      <div v-if="isChannelSelected && !searching">
        <Channel :key="contentKey"  :prev="getPrevChannel" :next="getNextChannel" :channel-id="selectedChannel"
                  :show-related-links="showAsCard"
                  :chart="chart"
                  @changeChannel="channel => selectChannel(channel)" class="pb-3" />
        <unlock-content-prompt v-if="!isPropertiesUnlocked && !disableUrlNavigation" :chart-id="chart.id" :category="getUnlockCategory"/>
      </div>

      <!--   Renders list of channels! -->
      <div v-else>

        <div v-if="showGrouped && !searching" class="pb-3">
          <div v-for="item in channelStates" :key="item.state || item.circuit" class="mt-3 bg-light p-3 casual-border-radius">

            <p class="d-flex align-items-center gap-5">
              <b-badge pill variant="primary" >
                {{item.count}}
              </b-badge>
              <span v-if="item.state !== undefined" class="">
                 {{$t(`hd.constants.channelCompositionStates${isConnectionChart(chart) ? '' : '.transit'}.${item.state}`) }}
              </span>
              <span v-if="item.circuit !== undefined">
                          {{$t('hd.constants.circuits.'+item.circuit) }}
              </span>

            </p>

            <channel-list-item v-for="channel in getChannels({state: item.state, circuit: item.circuit})" :key="channel.channel" :channel="channel"
                               @selectChannel="selectChannel"/>

          </div>
          <no-content v-if="channelStates.length === 0" :text="searching ? undefined : 'There are no defined Channels'"/>

        </div>


        <div v-else>

          <no-content v-if="getChannels({}).length === 0" :text="searching ? undefined : 'There are no defined Channels'"/>
          <channel-list-item v-for="channel in getChannels({})" :key="channel.channel" :channel="channel"
                             @selectChannel="selectChannel"/>
        </div>


      </div>
    </div>

  </div>
</template>

<script>

import {mapState} from "vuex";
import MbgTextInput from "../../elements/MbgTextInput";
import Channel from "./singles/Channel";
import util from "../../../mixins/util";

import ChannelsSetupMixin from "../../../mixins/ChannelsSetupMixin";
import ChannelListItem from "./list/ChannelListItem.vue";
import NoContent from "../../structure/NoContent.vue";
import UnlockContentPrompt from "../../billing/UnlockContentPrompt.vue";
import ChartUtil from "../../../mixins/ChartUtil";
import channels from "../../../helpers/rave/constants/channels";
import circuits from "../../../helpers/rave/constants/circuits";

export default{

  components:{UnlockContentPrompt, NoContent, ChannelListItem, Channel, MbgTextInput},
  props:{
    showAsCard: {
      type: Boolean,
      default: true
    },
    // This is "true" when we're on "analysis" but not on properties.
    // This disables URL navigation, and also hides the double "unlock" button.
    disableUrlNavigation: {
      type: Boolean,
      default: false,
    },
    showGrouped: {
      type: Boolean,
      default: true,
    }
  },
  mixins: [util, ChannelsSetupMixin, ChartUtil],
  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    isChannelSelected(){
      return this.selectedChannel !== null && this.selectedChannel >= 0;
    },

    channelStates() {
      // Handle personal charts based on circuits.
      if(this.isPersonalChart(this.chart)){
        let states = {};

        for(let channel of this.chart.chart.channels){
          const channelData = channels.find(x => x.id === channel);
          if(!Object.hasOwn(states, channelData.circuit)){
            states[channelData.circuit] = {
              circuit: channelData.circuit,
              count: 0
            }
          }

          states[channelData.circuit].count++;
        }
        const ordering = {
          [circuits.INTEGRATION]: 0,
          [circuits.KNOWING]: 1,
          [circuits.CENTERING]: 2,
          [circuits.LOGIC]: 3,
          [circuits.ABSTRACT]: 4,
          [circuits.EGO]: 5,
          [circuits.DEFENSE]: 6,

        }
        return Object.values(states).sort((a,b) =>
            ordering[a.circuit] - ordering[b.circuit] ) || [];
      } else {
        let states = {};
        for(let channel of this.chart.chart.channels){
          if(!Object.hasOwn(states, channel.state)){
            states[channel.state] = {
              state: channel.state,
              count: 0
            }
          }

          states[channel.state].count++;
        }
        return Object.values(states).sort((a,b) => b.count - a.count);
      }
    },

    getPrevChannel(){
      return this.channels[this.chart.chart.channels.findIndex(x => x === this.selectedChannel || x.id === this.selectedChannel)-1];
    },

    getNextChannel(){
      return this.channels[this.chart.chart.channels.findIndex(x => x === this.selectedChannel || x.id === this.selectedChannel)+1];
    }
  },
  watch:{
    search(val){
      this.searching = val && val !== '';
    },

    selectedChannel(val){
      this.$emit('hasSelectedItem', !isNaN(parseInt(val)));
      if(this.disableUrlNavigation) return;
      if(val) this.urlQuery.set('selected', val);
      else this.urlQuery.delete('selected');
      this.fixUrlQuery('?' + this.urlQuery.toString());
    }
  },


  data(){
    return{
      selectedChannel: null,
      search: null,
      searching: false,
      // channels: [],
      urlQuery: null,
      contentKey: 0,
    }
  },

  created(){
    this.urlQuery = this.getQuery()
    this.fixActiveChannel();
    this.setupChannelContent();
  },

  methods:{
    getChannels({state = undefined, circuit = undefined}){
      if(this.search) return this.channels.filter(x =>
          x.channel.toString().includes(this.search) ||
          x.name.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          x.keynote.toString().toLowerCase().includes(this.search.toLowerCase())
      )

      if(state !== undefined){
        return this.channels.filter(x => x.state === state)
      }

      if(circuit !== undefined){
        return this.channels.filter(x => x.circuit === circuit);
      }

      return this.channels;
    },
    fixActiveChannel(){
      this.selectedChannel = parseInt(this.urlQuery.get('selected'));
    },
    selectChannel(id){
      this.searching = false;
      this.selectedChannel = id;
      this.contentKey++;
    },
    goBack(){
      this.selectedChannel = null;
    }
  }
}
</script>
<style lang="scss">

#channels{
   .num-box{
     min-width:50px;
     font-size:10px;
  }
}
</style>