export default{
  methods:{
    fixUrlQuery(query){
      history.pushState(
          {},
          null,
          this.$route.path +query
      )
      // this.highlightBodygraph();
    },
    // applies url hash to the "currentHash" variable model.
    applyUrlHash() {
      if (window.location.href.split("#").length === 1) return;
      let hash = '#' + window.location.href.split("#")[1]

      this.$nextTick(()=>{
        this.currentHash = this.hashes.findIndex(x => x === hash);
        if (this.currentHash < 0) this.currentHash = 0;

      })
    },

    getPersonName(chartId, chart){
      try{
        return chart.meta[`person${(chartId+1)}`].name;
      }catch(e){
        return null;
      }
    },


    getQuery(){
      return new URLSearchParams(window.location.search)
    },
    getQueryById(key){
      return new URLSearchParams(window.location.search).get(key);
    },
  }
}