<template>
  <div id="pro-version-sidebar">
    <b-card v-for="(tab, index) in tabs" :id="`pro-version-accordion-${index}`" :key="tab.icon"
            :class="active === index ? 'active-pro-tab' : 'inactive-pro-tab'"
            class="pro-accordion ease-in-out duration-300 p-0"
    >

      <div class="pro-accordion-header py-3 px-2 cursor-pointer text-lg d-flex align-items-center gap-5"
           @click="toggleAccordion(index)">
        <span :class="tab.icon" class="mr-2"/> <h5 class="mb-0">{{ tab.title }}</h5>

        <span v-if="
        (tab.type === 'partnership-analysis' && !isPartnershipUnlocked)
          "
              class="ml-auto">
          <IconLock/>
        </span>

      </div>

      <div v-if="index === active" class="pro-accordion-data">
        <b-overlay :show="loading" rounded="sm">
          <component :is="tab.component" :key="internalKey"
                     :chart="chart"
                     :hide-authority="tab.hideAuthority"
                     :is-unlocked="tab.isUnlocked || 'false'"
                     class="p-0"
                     @loading="loadingChanged"/>
        </b-overlay>

        <unlock-content-prompt v-if="!isPartnershipUnlocked && tab.type === 'partnership-analysis'"
                               :chart-id="chart.id" category="companionship"/>


      </div>
    </b-card>

  </div>
</template>

<script>

import Summary from "../properties/summary.vue";
import PlanetaryActivations from "../properties/planetary-activations.vue";
import ChartUtil from "../../../mixins/ChartUtil";
import Centers from "../properties/centers.vue";
import Gates from "../properties/gates.vue";
import Channels from "../properties/channels.vue";
import UnlockContentPrompt from "../../billing/UnlockContentPrompt.vue";
import util from "../../../mixins/util";
import {mapState} from "vuex";
import IconLock from "../../icons/IconLock.vue";
import GatesOfLove from "@/components/charts/analysis-connection/gates-of-love.vue";
import FoundationalProperties from "@/components/charts/analysis-connection/foundational-properties.vue";

export default {
  components: {IconLock, UnlockContentPrompt},

  props: {
    chart: Object
  },
  data() {
    return {
      internalKey: 0,
      propertyHashes: ['summary', 'planetary-activations', 'centers', 'gates', 'channels'],
      propertyActiveHash: 0,
      loading: false,
      active: 0,
    }
  },
  mixins: [ChartUtil, util],

  mounted() {
    if (this.$route.query.active_pro_tab) {
      this.toggleAccordion(parseInt(this.$route.query.active_pro_tab));
    }
  },
  computed: {
    ...mapState({
      propertiesKey: state => state.ui.propertiesKey,
    }),

    isPartnershipUnlocked() {
      return this.getSubscriptionStatus.companionship || this.getUnlockStatus(this.chart).companionship
    },

    tabs() {
      return [
        // properties tabs
        {
          title: this.$t('general.pro.summary'),
          icon: 'icon-summary',
          isUnlocked: this.isPartnershipUnlocked,
          component: Summary
        },
        {
          title: this.$t('charts.properties.planetary'),
          icon: 'icon-planetary-activations',
          isUnlocked: this.isPartnershipUnlocked,
          component: PlanetaryActivations
        },
        {
          title: this.$t('hd.properties.centers'),
          icon: 'icon-centers',
          isUnlocked: this.isPartnershipUnlocked,
          component: Centers
        },
        {
          title: this.$t('hd.gates.name.many'),
          icon: 'icon-gate',
          isUnlocked: this.isPartnershipUnlocked,
          component: Gates
        },
        {
          title: this.$t('hd.properties.channels'),
          icon: 'icon-channels',
          isUnlocked: this.isPartnershipUnlocked,
          component: Channels
        },
        // analysis now!
        {
          type: 'partnership-analysis',
          id: 7,
          icon: 'icon-summary',
          component: FoundationalProperties,
          title: this.$t('charts.analysis.connection.tabs.7'),

        },
        {
          type: 'partnership-analysis',
          id: 6,
          icon: 'icon-love',
          component: GatesOfLove,
          title: this.$t('charts.analysis.connection.tabs.6'),
          isUnlocked: this.isPartnershipUnlocked,
        },
      ]
    }
  },

  watch: {
    propertiesKey() {
      this.loaded = false;
      this.$nextTick(()=>{
        this.fixActivePropertyTab();
      })

    }
  },

  methods: {


    fixActivePropertyTab() {
      let activeTab = this.propertyHashes.findIndex(x => x === this.getQueryById('tab'));
      if (activeTab < 0) activeTab = 0;

      this.$nextTick(() => {
        this.toggleAccordion(activeTab, true);
        this.loaded = true; // we want to render tabs only after we have applied the correct active tab // that way we avoid double renders.
      });
    },
    loadingChanged(val) {
      this.loading = val;
    },
    toggleAccordion(index, keepQuery = false) {
      if (!keepQuery) this.fixUrlQuery('?active_pro_tab=' + index);

      if (this.active === index) {
        if (!keepQuery) this.active = null;
        else this.internalKey++;
      } else {
        this.active = index;
      }
      if (this.active !== null) {
        setTimeout(() => {
          const top = document.getElementById('pro-version-accordion-' + this.active);


          if (window.innerWidth >= 1240 && window.innerHeight >= 700) {
            document.getElementById('pro-version-sidebar').scrollTo({
              top: top.offsetTop - 50,
              behavior: 'smooth'
            });
          } else if (window.innerWidth < 540) {
            top.scrollIntoView({
              behavior: 'smooth'
            });
          } else {
            window.scrollTo({
              top: top.offsetTop,
              behavior: 'smooth'
            });
          }


        }, 300);
      }
    }
  }
}
</script>