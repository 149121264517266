<template>
  <div id="related-links" class="px-0 px-md-1">
    <div v-if="getPlanetaryActivations.length > 0" class="planets">
      <p class="my-2 d-flex align-items-center">{{ $t('charts.properties.planetary') }}</p>
      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle v-for="planet in getPlanetaryActivations" :key="`planet-${planet.id}-${planet.activation}`"
                             type="planet"
                             :is-transit="isTransitPlanet(planet)"
                             start-open
                             :depth="depth"
                             :is-reverse-background="isNested"
                             :render-props="{
                                 id: planet.id,
                                 planet,
                                 planetId: planet.id,
                                 activation: planet.activation,
                                 chartId: planet.chartId,
                                 getIcon: () => {},
                                 customIconClasses: getPlanetColorClasses(planet),
                                 reverseBackground: !isNested,
                                 isGateChild: true
                              }"
                             :label="getPlanetText(planet)"
        />
      </div>
    </div>
    <div v-if="!hideCenter" class="center">
      <p class="my-2 d-flex flex-wrap align-items-center">{{ $t('hd.objects.center') }}</p>
      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle :key="`center-${getCenter.id}`"
                             type="center"
                             :render-props="{
                                  id: getCenter.id,
                                  centerId: getCenter.id,
                                  state: getCenter.state,
                                  reverseBackground: !isNested,
                                  chart,
                             }"
                             :depth="depth"
                             :is-nested="isNested"
                             :is-reverse-background="isNested"
                             :label="`<span class='text-md icon-center-${getCenter.icon}'></span>`"
                             :text="getCenter.state + ' ' + $t('hd.centers.'+ getCenter.id + '.name') + ' ' + $t('hd.objects.center')"
        />
      </div>
   </div>
    <div v-if="isChannelDefined" class="channel">
      <p class="my-2 d-flex align-items-center">{{ $t('hd.properties.channels') }}</p>
      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle v-for="channel in [getChannel]" :key="`channel-${channel.id}`"
                             icon="icon-channels"
                             type="channel"
                             :item="{
                                type: 'channel',
                                id: channel.id,
                             }"
                             :depth="depth"
                             :is-nested="isNested"
                             :is-reverse-background="isNested"
                             :render-props="{
                                id: channel.id,
                                channelId: channel.id,
                                reverseBackground: !isNested,
                                chart,
                             }"
                             :text="$t('hd.channels.'+ channel.id + '.name')"
        />
      </div>
    </div>
    <div v-if="isChannelDefined" class="harmonic-gate">
      <p class="my-2 d-flex align-items-center">{{ $t('charts.properties.harmonicGate') }}</p>
      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle v-for="gate in getHarmonicGates" :key="`gate-${gate}`"
                             type="gate"
                             :render-props="{
                                  id: gate,
                                  gateId: gate,
                                  reverseBackground: !isNested,
                                  chart,
                              }"
                             :depth="depth + 1"
                             :is-nested="isNested"
                             :is-reverse-background="isNested"
                             :label="getGateToggleLabel(gate)"
        />
      </div>
    </div>
  </div>
</template>

<script>

import util from "../../../../mixins/util";
import {mapState} from "vuex";
import channels from "../../../../helpers/rave/constants/channels";
import centers from "../../../../helpers/rave/constants/centers";
import ChartUtil from "../../../../mixins/ChartUtil";
import RelatedLinkToggle from "./partials/RelatedLinkToggle.vue";
import RelatedLinksMixin from "../../../../mixins/RelatedLinksMixin";

export default{
  components: {RelatedLinkToggle},
  mixins: [util, ChartUtil, RelatedLinksMixin],
  props:{
    selectedId: Number,
    type: String,
    isNested: Boolean,
    depth: Number,
  },
  data(){
    return {
      channels
    }
  },
  computed:{
    ...mapState({
      chart: state => state.charts.chart,
      chart_data: state => state.charts.chart.chart,
      tabs: state => state.ui.tabsMapping
    }),

    hideCenter(){
      return this.getQueryById('tab') === this.tabs.center && +this.getQueryById('selected') === this.getCenter.id;
    },

    getChannel(){
      return this.channels.find(x => x.gates.includes(this.selectedId))
    },

    getPlanetaryActivations(){
      return this.chart_data.planets.filter(x => x.gate === this.selectedId).filter(x => !this.hideActivation(x));
    },

    isChannelDefined() {
      return this.chart_data.channels.findIndex(x => {
        // for connection or composite charts!
        if (typeof x === 'object') return x?.id === this.getChannel.id

        return x === this.getChannel.id
      }) > -1;
    },
    getHarmonicGates(){
      let gates = [];
      let channels = this.channels.filter(x => x.gates.includes(this.selectedId));
      for(let channel of channels){
        let gate = channel.gates.find(x => x !== this.selectedId)
        gates.push(gate)
      }
      return gates;
    },

    getCenter(){

      let center = centers.centersList.find(x => x.gates.includes(parseInt(this.selectedId)));
      center.state = this.chart_data.centers[center.id] === centers.centerDefinitionStates.DEFINED ?
          this.$t('hd.constants.centerDefinitionStates.2') : this.$t('hd.constants.centerDefinitionStates.1')
      return center
    },


  },

  methods:{
    hideActivation(planet){
      return this.getQueryById('tab') === this.tabs.planet
          && +this.getQueryById('selected') === planet.id
      && +this.getQueryById('chart') === planet.chartId
      && +this.getQueryById('activation') === planet.activation;
    },
  }
}
</script>