<template>
  <div id="pro-version-sidebar">
    <b-card v-for="(tab, index) in tabs" :id="`pro-version-accordion-${index}`" :key="tab.icon"
            :class="active === index ? 'active-pro-tab' : 'inactive-pro-tab'"
            class="pro-accordion ease-in-out duration-300 p-0"
    >

      <div class="pro-accordion-header py-3 px-2 cursor-pointer text-lg d-flex align-items-center gap-5"
           @click="toggleAccordion(index)">
        <span :class="tab.icon" class="mr-2"/> <h5 class="mb-0">{{ tab.title }}</h5>

        <span v-if="
        (tab.type === 'foundational-analysis' && !isFoundationalUnlocked) ||
        (tab.type === 'advanced-analysis' && !isAdvancedUnlocked) ||
        (tab.type === 'work-success-analysis' && !isWorkSuccessUnlocked)
          "
              class="ml-auto">
          <IconLock/>
        </span>

      </div>

      <div v-if="index === active" class="pro-accordion-data">
        <b-overlay :show="loading" rounded="sm">
          <component :is="tab.component" :key="internalKey"
                     :chart="chart"
                     :hide-authority="tab.hideAuthority"
                     :is-unlocked="tab.isUnlocked || 'false'"
                     class="p-0"
                     @loading="loadingChanged"/>
        </b-overlay>

        <unlock-content-prompt v-if="!isFoundationalUnlocked && tab.type === 'foundational-analysis'"
                               :chart-id="chart.id" category="foundational"/>
        <unlock-content-prompt v-if="!isAdvancedUnlocked && tab.type === 'advanced-analysis'" :chart-id="chart.id"
                               category="advanced"/>

      </div>
    </b-card>

  </div>
</template>

<script>

import Summary from "../properties/summary.vue";
import PlanetaryActivations from "../properties/planetary-activations.vue";
import ChartUtil from "../../../mixins/ChartUtil";
import Centers from "../properties/centers.vue";
import Gates from "../properties/gates.vue";
import Channels from "../properties/channels.vue";
import typeStrategyAuthority from "../analysis/foundational/type-strategy-authority.vue";
import UnlockContentPrompt from "../../billing/UnlockContentPrompt.vue";
import nourish from "../analysis/advanced/nourish.vue";
import path from "../analysis/advanced/path.vue";
import vision from "../analysis/advanced/vision.vue";
import awareness from "../analysis/advanced/awareness.vue";
import variable from "../analysis/advanced/variable.vue";
import Profile from "../analysis/foundational/sections/Profile.vue";
import IncarnationCross from "../analysis/foundational/sections/IncarnationCross.vue";
import NodalPolarities from "../analysis/foundational/sections/NodalPolarities.vue";
import Definition from "../analysis/foundational/sections/Definition.vue";
import util from "../../../mixins/util";
import {mapState} from "vuex";
import Authority from "../analysis/foundational/sections/Authority.vue";
import IconLock from "../../icons/IconLock.vue";
import introduction from "@/components/charts/analysis/work_success/introduction.vue";
import wcType from "@/components/charts/analysis/work_success/wc-type.vue";
import wcDefinition from "@/components/charts/analysis/work_success/wc-definition.vue";
import wcGates from "@/components/charts/analysis/work_success/wc-gates.vue";
import wcChannels from "@/components/charts/analysis/work_success/wc-channels.vue";
import wcProfile from "@/components/charts/analysis/work_success/wc-profile.vue";
import wcProfitCenters from "@/components/charts/analysis/work_success/wc-profit-centers.vue";

export default {
  components: {IconLock, UnlockContentPrompt},

  props: {
    chart: Object
  },
  data() {
    return {
      internalKey: 0,
      propertyHashes: ['summary', 'planetary-activations', 'centers', 'gates', 'channels'],
      propertyActiveHash: 0,
      loading: false,
      active: 0,
    }
  },
  mixins: [ChartUtil, util],

  mounted() {
    if (this.$route.query.active_pro_tab) {
      this.toggleAccordion(parseInt(this.$route.query.active_pro_tab));
    }
  },
  computed: {
    ...mapState({
      propertiesKey: state => state.ui.propertiesKey,
    }),

    isFoundationalUnlocked() {
      return this.getSubscriptionStatus.foundational || this.getUnlockStatus(this.chart).foundational
    },

    isAdvancedUnlocked() {
      return this.getSubscriptionStatus.advanced || this.getUnlockStatus(this.chart).advanced
    },

    isWorkSuccessUnlocked() {
      return this.getSubscriptionStatus.work_success || this.getUnlockStatus(this.chart).work_success
    },

    tabs() {
      return [
        // properties tabs
        {
          title: this.$t('general.pro.summary'),
          icon: 'icon-summary',
          isUnlocked: this.isFoundationalUnlocked,
          component: Summary
        },
        {
          title: this.$t('charts.properties.planetary'),
          icon: 'icon-planetary-activations',
          isUnlocked: this.isFoundationalUnlocked,
          component: PlanetaryActivations
        },
        {
          title: this.$t('hd.properties.centers'),
          icon: 'icon-centers',
          isUnlocked: this.isFoundationalUnlocked,
          component: Centers
        },
        {
          title: this.$t('hd.gates.name.many'),
          icon: 'icon-gate',
          isUnlocked: this.isFoundationalUnlocked,
          component: Gates
        },
        {
          title: this.$t('hd.properties.channels'),
          icon: 'icon-channels',
          isUnlocked: this.isFoundationalUnlocked,
          component: Channels
        },
        // foundational analysis
        {
          id: 1,
          type: 'foundational-analysis',
          icon: 'icon-person-circle',
          component: typeStrategyAuthority,
          hideAuthority: true,
          title: this.$t('hd.properties.type')
        },
        {
          id: 2,
          type: 'foundational-analysis',
          icon: 'icon-authority',
          component: Authority,
          title: this.$t('general.pro.authority')
        },
        {
          id: 3,
          type: 'foundational-analysis',
          icon: 'icon-electromagnetic-connections',
          component: Definition,
          title: this.$t('hd.properties.definition')
        },
        {
          id: 5,
          type: 'foundational-analysis',
          icon: 'icon-circles',
          component: Profile,
          title: this.$t('hd.properties.profile')
        },
        {
          id: 6,
          type: 'foundational-analysis',
          icon: 'icon-cross',
          component: IncarnationCross,
          title: this.$t('hd.properties.cross')
        },
        {
          id: 7,
          type: 'foundational-analysis',
          icon: 'icon-nodal-polarities',
          component: NodalPolarities,
          title: this.$t('hd.properties.nodalPolarities')
        },
        // advanced analysis
        {
          id: 1,
          type: 'advanced-analysis',
          icon: 'icon-brain',
          component: nourish,
          title: this.$t('general.pro.nourish')
        },
        {
          id: 2,
          type: 'advanced-analysis',
          icon: 'icon-path',
          component: path,
          title: this.$t('general.pro.path')
        },
        {
          id: 3,
          type: 'advanced-analysis',
          icon: 'icon-vision',
          component: vision,
          title: this.$t('general.pro.vision')
        },
        {
          id: 4,
          type: 'advanced-analysis',
          icon: 'icon-awareness',
          component: awareness,
          title: this.$t('general.pro.mind')
        },
        {
          id: 5,
          type: 'advanced-analysis',
          icon: 'icon-variable',
          component: variable,
          title: this.$t('charts.analysis.advanced.tabs.5')
        },
        {
          id: 0,
          type: 'work-success-analysis',
          icon: 'icon-v2-work-success',
          component: introduction,
          title: this.$t('charts.analysis.work_success.tabs.0')
        },
        {
          id: 1,
          type: 'work-success-analysis',
          icon: 'icon-person-circle',
          component: wcType,
          title: this.$t('charts.analysis.work_success.tabs.1')
        },
        {
          id: 2,
          type: 'work-success-analysis',
          icon: 'icon-definition',
          component: wcDefinition,
          title: this.$t('charts.analysis.work_success.tabs.2')
        },
        {
          id: 3,
          type: 'work-success-analysis',
          icon: 'icon-v2-penta-gates',
          component: wcGates,
          title: this.$t('charts.analysis.work_success.tabs.3')
        },
        {
          id: 4,
          type: 'work-success-analysis',
          icon: 'icon-v2-penta-channels',
          component: wcChannels,
          title: this.$t('charts.analysis.work_success.tabs.4')
        },
        {
          id: 5,
          type: 'work-success-analysis',
          icon: 'icon-v2-profile',
          component: wcProfile,
          title: this.$t('charts.analysis.work_success.tabs.5')
        },
        {
          id: 6,
          type: 'work-success-analysis',
          icon: 'icon-v2-profit-centers',
          component: wcProfitCenters,
          title: this.$t('charts.analysis.work_success.tabs.6')
        },
      ]
    }
  },

  watch: {
    propertiesKey() {
      this.loaded = false;
      this.$nextTick(()=>{
        this.fixActivePropertyTab();
      })

    }
  },

  methods: {


    fixActivePropertyTab() {
      let activeTab = this.propertyHashes.findIndex(x => x === this.getQueryById('tab'));
      if (activeTab < 0) activeTab = 0;

      this.$nextTick(() => {
        this.toggleAccordion(activeTab, true);
        this.loaded = true; // we want to render tabs only after we have applied the correct active tab // that way we avoid double renders.
      });
    },
    loadingChanged(val) {
      this.loading = val;
    },
    toggleAccordion(index, keepQuery = false) {
      if (!keepQuery) this.fixUrlQuery('?active_pro_tab=' + index);

      if (this.active === index) {
        if (!keepQuery) this.active = null;
        else this.internalKey++;
      } else {
        this.active = index;
      }
      if (this.active !== null) {
        setTimeout(() => {
          const top = document.getElementById('pro-version-accordion-' + this.active);


          if (window.innerWidth >= 1240 && window.innerHeight >= 700) {
            document.getElementById('pro-version-sidebar').scrollTo({
              top: top.offsetTop - 50,
              behavior: 'smooth'
            });
          } else if (window.innerWidth < 540) {
            top.scrollIntoView({
              behavior: 'smooth'
            });
          } else {
            window.scrollTo({
              top: top.offsetTop,
              behavior: 'smooth'
            });
          }


        }, 300);
      }
    }
  }
}
</script>