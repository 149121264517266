<template>
  <div class="center">
    <b-overlay :show="loading" rounded="sm">
      <center-header-nav
          v-if="!isRelatedToggle"
          :center="center"
          :center-id="centerId"
          :prev="prev"
          :next="next"
          :subtitle="subtitle"
          @changeCenter="val => $emit('changeCenter', val)"
      />

      <center-content :center-id="centerId" :loading="loading" :state="state" :type="type"
                        @changeSubtitle="sub => this.subtitle = sub"
                        :reverse-background="reverseBackground"
                        @loading="val => loading = val" />

      <single-center-partnership :key="centerId" :chart="chart" :center-id="centerId" :reverse-background="reverseBackground" />

      <center-related-links
          v-if="showRelatedLinks"
          :selected-id="centerId"
          :depth="depth"
          :is-nested="isRelatedToggle"
      />
    </b-overlay>
  </div>


</template>

<style lang="scss">
.center .centerary-tab {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
</style>
<script>
import {mapState} from "vuex";
import CenterContent from "../content/CenterContent";
import CenterRelatedLinks from "../related-links/CenterRelatedLinks";
import CenterHeaderNav from "@/components/charts/analysis-connection/partials/CenterHeaderNav.vue";
import PartnershipAnalysis from "@/mixins/PartnershipAnalysis";
import SingleCenterPartnership from "@/components/charts/analysis-connection/partials/SingleCenterPartnership.vue";

export default {
  components: {SingleCenterPartnership, CenterHeaderNav, CenterRelatedLinks, CenterContent},
  mixins: [PartnershipAnalysis],
  props: {
    isRelatedToggle: {
      type: Boolean,
      default: false,
    },
    showRelatedLinks: {
      type: Boolean,
      default: true
    },
    depth: Number,
    centerId: Number,
    state: Number,
    type: String,
    center: Object,
    prev: Object,
    next: Object,
    reverseBackground: Boolean
  },

  computed: {
    ...mapState({
      chart: state => state.charts.chart
    }),
  },
  data() {
    return {
      loading: false,
      subtitle: ''
    }
  },

}
</script>