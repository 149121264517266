<template>
  <div class="px-4">
    <inner-tabs-renderer :id="2"
                         type="foundational"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>

</template>

<script>

import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import Centers from "../../properties/centers";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";
import Bridges from "@/components/charts/analysis/foundational/sections/Bridges.vue";
export default{
  mixins: [AnalysisQueryMixin],
  components: {InnerTabsRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      openCentersIntro: null,
      selectedCenter: false,
      selectedBridge: false,
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs(){
      return [
        {
          id: 0,
          title: this.$t('hd.properties.introduction'),
          shouldRender: !!this.openCentersIntro,
          component: DynamicZoneRenderer,
          props: {
            components: this.openCentersIntro ? this.openCentersIntro.components : [],
            addPaddings: false,
          }
        },
        {
          id: 1,
          title: this.$t('hd.constants.centerDefinitionStates.1') + ' ' + this.$t('hd.properties.centers'),
          shouldRender: true,
          component: Centers,
          hideProgress: this.selectedCenter,
          props: {
            showTab: false,
            showAsCard: false,
            type: 'open',
            disableUrlNavigation: true
          },
          on: {
            'hasSelectedItem': (val) => this.selectedCenter = val
          }
        },
        {
          id: 2,
          title: this.$t('hd.properties.bridges'),
          hideTab: process.env.VUE_APP_ENABLE_BRIDGES !== 'true' || !this.chart?.chart?.bridges,
          shouldRender: process.env.VUE_APP_ENABLE_BRIDGES === 'true' && !!this.chart?.chart?.bridges,
          component: Bridges,
          hideProgress: this.selectedBridge,
          props: {
            bridges: this.chart?.chart?.bridges,
          },
          on: {
            'hasSelectedItem': (val) => this.selectedBridge = val
          }
        }
      ]
    }
  },
  created(){
    this.getOpenCentersIntroduction();
  },

  methods:{
    getOpenCentersIntroduction(){
      let url = '/analysis/introductions/introduction.open-centers';
      this.getContent('openCentersIntro', url);
    },
  }
}
</script>