import {getFoundationalProperties} from "@/helpers/charts";

const isPersonalChart = (chart) => {
    return chart?.meta?.type === 'RAVE_CHART'
}

const htmlReplacement = (value) => `<span class="text-primary font-weight-bold">${value}</span>`;

const conditionalGatesRegex =  /\[if_has_gate_(\d{1,2})\]([\s\S]*?)\[\s*\/end\s*\]/g;

const replaceDetailsInString = (string, details) => {
    const attributes = {
        type: details.find(x => x.key === 'type')?.value,
        strategy: details.find(x => x.key === 'strategy')?.value,
        definition: details.find(x => x.key === 'definition')?.value,
        notSelfTheme: details.find(x => x.key === 'not-self-theme')?.value,
        authority: details.find(x => x.key === 'authority')?.value,
        profile: details.find(x => x.key === 'profile')?.value,
        cross: details.find(x => x.key === 'cross')?.value,
    }

    return string
        .replaceAll('[authority]', htmlReplacement(attributes.authority))
        .replaceAll('[type]', htmlReplacement(attributes.type))
        .replaceAll('[strategy]', htmlReplacement(attributes.strategy))
        .replaceAll('[definition]', htmlReplacement(attributes.definition))
        .replaceAll('[not-self-theme]', htmlReplacement(attributes.notSelfTheme))
        .replaceAll('[profile]', htmlReplacement(attributes.profile))
        .replaceAll('[cross]', htmlReplacement(attributes.cross))
}

const processAllKeys = (data, personalProperties) => {
    if (!data) return data;
    for (const key of Object.keys(data)) {
        if (typeof data[key] === 'string') data[key] = replaceDetailsInString(data[key], personalProperties);
    }
}

const processPersonalChartDetails = (data, personalProperties) => {
    if (!data) return data;
    if (typeof data === 'string') return replaceDetailsInString(data, personalProperties);
    if (typeof data !== 'object') return data;

    // components is a known array of objects
    if (data.components) {
        for (let component of data.components) {
            if (typeof component !== 'object') continue;
            processAllKeys(component, personalProperties)
        }
    }

    processAllKeys(data, personalProperties);

    return data;
}

const processConditionalContent = (data, chart) => {
    if (typeof data !== 'object') return data;

    if (!data.components) return data;

    for (let component of data.components) {
        if (typeof component !== 'object') continue;
        if (!component.content) continue;

        const matches = [...component.content.matchAll(conditionalGatesRegex)];

        matches.forEach((match) => {
            const gate = +match[1];

            const hasGate = chart?.chart?.gates?.some(x => x.gate === gate);

            component.content = component.content.replace(match[0], hasGate ? match[2] : '');
        })
    }

    return data;
}

export default{
    props:{
        order: Number,
        hasNext: {
            type: Boolean,
            default: true,
        },
        hasPrev: {
            type: Boolean,
            default: true
        },
        totalTabs: {
            type: Number,
            default: 1
        }
    },

    data(){
        return{
            fetching: false
        }
    },

    beforeDestroy() {
        this.$emit('loading', false);
    },

    methods: {
        bulkGetContent(dataKey, type, ids, chart) {
            const cId = chart?.id || this.$route.params.chartId;
            this.$emit('loading', true);
            this.fetching = true;

            return this.axios.get(`/content/${cId}/${type}/bulk`,{
                params: { ids }
            }).then((res) => {
                let data = res.data;
                const chartObj = chart || this.$store.state.charts.chart;
                if (isPersonalChart(chartObj)) {
                    for (const key of Object.keys(data)) {
                        data[key] =  processPersonalChartDetails(data[key], getFoundationalProperties(chartObj));
                        data[key] = processConditionalContent(data[key], chartObj);
                    }
                }

                this[dataKey] = data;
            }).catch(()=>{})
                .finally(()=>{
                this.fetching = false;
                this.$emit('loading', false);
            })
        },
        getContent(key, url, twoLevelDepth = false, chart){
            this.$emit('loading', true);
            this.fetching = true;

            return this.axios.get('/content/' + this.$route.params.chartId + url).then(res=> {

                let data = res.data;
                const chartObj = chart || this.$store.state.charts.chart;
                // for personal charts, let's do replacement of base attributes.
                if (isPersonalChart(chartObj)){
                    data = processPersonalChartDetails(data, getFoundationalProperties(chartObj));
                    data = processConditionalContent(data, chartObj);
                }


                // if we have depth (for example trying to assign value to channelContent.1 = x instead of channelContent = x, we are passing two level depth)
                if(twoLevelDepth){
                    let parts = key.split('.');
                    this[parts[0]][parts[1]] = data;
                }else{
                    this[key] = data;
                }

                this.audioKey++;
                return data;
            }).catch(e=>{
                console.log(e);
            }).finally(()=>{
                this.fetching = false;
                this.$emit('loading', false);
            });
        },

        getArrayContent(key, url){
            this.$emit('loading', true);
            this.fetching = true;

            return this.axios.get('/content/' + this.$route.params.chartId + url).then(res=>{
                this[key].push(res.data);
                this.audioKey++;
            }).catch(e=>{
                console.log(e);
            }).finally(()=>{
                this.fetching = false;
                this.$emit('loading', false);
            });
        }
    }
}