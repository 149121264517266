<template>
  <div id="related-links" class="px-0 px-md-1">
    <div v-if="getChannels.length > 0" class="channels">
      <p class="my-2 d-flex align-items-center">{{ $t('hd.properties.channels') }}</p>
      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle v-for="channel in getChannels" :key="`channel-${channel.id}`"
                             icon="icon-channels"
                             type="channel"
                             :item="{
                                type: 'channel',
                                id: channel.id
                             }"
                             :depth="depth"
                             :is-nested="isNested"
                             :is-reverse-background="isNested"
                             :render-props="{id: channel.id, channelId: channel.id, reverseBackground: !isNested, chart}"
                             :text="$t('hd.channels.'+ channel.id + '.name')" />
      </div>
    </div>
    <div v-if="getGates.length > 0" class="gates">
      <p class="my-2 d-flex align-items-center">{{ $t('hd.gates.name.many') }}</p>

      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle v-for="gate in getGates" :key="`gate-${gate}`"
                             type="gate"
                             :is-nested="isNested"
                             :is-reverse-background="isNested"
                             :depth="depth"
                             :render-props="{ id: gate, gateId: gate, reverseBackground: !isNested, chart }"
                             :label="getGateToggleLabel(gate)" />
      </div>
    </div>
  </div>
</template>

<script>

import util from "../../../../mixins/util";
import {mapState} from "vuex";
import channels from "@/helpers/rave/constants/channels";
import centers from "../../../../helpers/rave/constants/centers";
import RelatedLinkToggle from "./partials/RelatedLinkToggle";
import RelatedLinksMixin from "../../../../mixins/RelatedLinksMixin";

export default{
  components: {RelatedLinkToggle},
  mixins: [util, RelatedLinksMixin],
  props:{
    selectedId: Number,
    isNested: Boolean,
    depth: Number
  },
  computed:{
    ...mapState({
      chart: state => state.charts.chart,
      chart_data: state => state.charts.chart.chart,
      tabs: state => state.ui.tabsMapping
    }),

    getChannels(){
      let channelList = [];
      let centerGates = this.getGates;
      for(let gate of centerGates){
        let possibleChannel = channels.find(x => x.gates.includes(gate));

        let gateRequired = possibleChannel.gates.find(x => x !== gate);

        if(this.chart_data.gates.find(x => x.gate === gateRequired)){
          channelList.push(possibleChannel);
        }
      }

      return channelList;
    },

    channel(){
      return centers.getById(this.selectedId);
    },

    getGates(){
      let chartGates = this.chart_data.gates.map(x => x.gate);
      let enabledGates = [];
      for(let gate of this.channel.gates){
        if(chartGates.includes(gate)){
          enabledGates.push(gate);
        }
      }
      return enabledGates;
    },

  },
}
</script>