<template>
  <div @click="select"
       @mouseover="highlightsDisabled ? undefined : highlightElement({type: types.channel, id: channel.channel, extras: {channelGates: channel.gates}})"
       @mouseleave="clearAllHighlights" class="highlight-trigger">
    <b-card class="my-2 cursor-pointer border-primary">
      <div class="d-flex align-items-center">
        <div>
          <div class="num-box float-left text-center cursor-pointer" >
            {{channel.key}}
          </div>
        </div>
        <div>
          <label class="font-weight-light">{{channel.name }}</label>
          <h6>{{channel.keynote}}</h6>
        </div>
        <div class="ml-auto">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import highlightingMixin from "../../../../mixins/HighlightingMixin";
export default {

  mixins: [highlightingMixin],

  props: {
    channel: Object,
    highlightsDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    select(){
      this.clearAllHighlights();
      this.$emit('selectChannel', this.channel.channel)
    }
  }
}
</script>