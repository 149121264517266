export default{
    birthDateLocal: 'Birth Data (Local)',
    birthDateUTC: 'Birth Data (UTC)',
    birthDate: 'Birth Date',
    birthTime: 'Birth Time',
    designDate: 'Design Date',
    country: 'Country',
    timezone: 'UTC Time',
    city: 'City',
    whichCountry: 'Birth Country',
    whichCity: 'Birth City',
    chartName: 'Chart Name',
    chartNamePlaceholder: '\'s Chart',
    searchChart: 'Search Chart...',
    foundational: 'Foundational Properties',
    advanced: 'Advanced Properties',
    connectionTheme: 'Connection Theme',
    electromagneticCount: 'Electromagnetic Connections',
    dominanceCount: 'Dominance Connections',
    compromiseCount: 'Compromise Connections',
    companionshipCount: 'Companionship Connections',

    electromagnetic: 'Electromagnetic',
    dominance: 'Dominance',
    compromise: 'Compromise',
    companionship: 'Companionship',

    transitLocal: 'Transit Time (Local)',
    transitUtc: 'Transit Time (UTC)',
    transitCross: 'Transit Cross',
    cycleLocal: 'Cycle Time (Local)',
    cycleUtc: 'Cycle Time (UTC)',
    cycleDesign: 'Cycle Design (Local)',
    cycleCross: 'Cycle Cross',
    age: 'Age',
    openInNewTab: 'View (in new tab)'
}