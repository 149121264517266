<template>
    <div v-if="content">
      <dynamic-zone-renderer :components="content.components" />
    </div>
</template>

<script>

import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";

export default{
  components: {DynamicZoneRenderer},
  mixins: [AnalysisQueryMixin],
  props:{
    id: [String, Number],
  },
  data(){
    return{
      audioKey: 0,
      content: null
    }
  },

   created(){
    this.getIntro();
   },

  methods:{
    getIntro(){
      let url = '/analysis/introductions/cycle-chart.information';
      this.getContent('content', url);
    }
  }
}
</script>