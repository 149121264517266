import channelCompositionStates from "@/helpers/rave/constants/channelCompositionStates";
import ChannelsSetupMixin from "@/mixins/ChannelsSetupMixin";
import ChartUtil from "@/mixins/ChartUtil";
import AnalysisQueryMixin from "@/mixins/AnalysisQueryMixin";
import Vue from "vue";
import channelConstants from "@/helpers/rave/constants/channels";

function splitAtFirstH2(str) {
    const index = str.indexOf("</h2>");
    if (index === -1) return [str, ""]; // If not found, return the whole string as the first part

    return [str.substring(0, index + 5), str.substring(index + 5)]; // Include the "</h2>" in the first part
}

export default {
    mixins: [ChannelsSetupMixin, ChartUtil, AnalysisQueryMixin],

    created() {
        if (this.isConnectionChart(this.chart)) this.setupChannelContent();
    },


    data() {
        return {
            // This is being used on "single" screens, to avoid
            // delegating fetching to the components (for simplicity).
            singleAnalysisContent: {
                dominance: null,
                electromagnetic: null,
                companionship: null,
                compromise: null,
            },
        }
    },

    computed: {
        getCenterDynamicIntros() {
            if (!this.CenterDynamics) return {};

            return {
                [this.CenterDynamics.Combo]: '/analysis/introductions/partnership-analysis.defined-undefined-centers',
                [this.CenterDynamics.UndefinedToDefined]: '/analysis/introductions/partnership-analysis.mutually-defined-centers',
                [this.CenterDynamics.UndefinedToUndefined]: '/analysis/introductions/partnership-analysis.shared-undefined-centers',
                [this.CenterDynamics.Defined]: '/analysis/introductions/partnership-analysis.shared-defined-centers',
            }
        },
        // assumes chart is accessible on `this.chart`
        getElectromagneticChannels() {
            if (!this.isConnectionChart(this.chart)) return [];
            return this.channels.filter(x => x.state === channelCompositionStates.ELECTROMAGNETIC);
        },

        getDominanceChannels() {
            if (!this.isConnectionChart(this.chart)) return [];
            return this.channels.filter(x => x.state === channelCompositionStates.DOMINANCE);
        },

        getCompromiseChannels() {
            if (!this.isConnectionChart(this.chart)) return [];
            return this.channels.filter(x => x.state === channelCompositionStates.COMPROMISE);
        },

        getCompanionshipChannels() {
            if (!this.isConnectionChart(this.chart)) return [];
            return this.channels.filter(x => x.state === channelCompositionStates.COMPANIONSHIP);
        }
    },

    methods: {
        getForChannel(channel) {
            const electromagnetic = this.getElectromagneticChannels.some(x => x.channel === channel.channel);
            const dominance = this.getDominanceChannels.some(x => x.channel === channel.channel);
            const compromise = this.getCompromiseChannels.some(x => x.channel === channel.channel);
            const companionship = this.getCompanionshipChannels.some(x => x.channel === channel.channel);

            if (electromagnetic) {
                this.getContentForChannel('electromagnetic', 'singleAnalysisContent', channel.channel, {
                    saveLocally: true,
                });
            }
            if (dominance) {
                this.getContentForChannel('dominance', 'singleAnalysisContent', channel.channel, {
                    saveLocally: true,
                });
            }
            if (compromise) {
                this.getContentForChannel('compromise', 'singleAnalysisContent', channel.channel, {
                    dominantChartId: channel.dominantChartId,
                    saveLocally: true,
                });
            }
            if (companionship) {
                this.getContentForChannel('companionship', 'singleAnalysisContent', channel.channel, {
                    saveLocally: true,
                });
            }
        },
        getIntroduction(kind, contentKey) {
            let key;
            if (kind === 'electromagnetic') key = `introduction.electromagnetic`;
            if (kind === 'dominance') key = `introduction.dominance`;
            if (kind === 'compromise') key = `introduction.compromise`;
            if (kind === 'companionship') key = `introduction.companionship`;

            this.getContent(contentKey || 'content', `/analysis/introductions/${key}`);
           },

        // this gets the content and saves in an object {} keyed by channelId.
        // this `key` must be a "data" variable, being an object.
        getContentForChannel(kind, key, channelId, extras) {
            let url;

            if (!extras?.saveLocally) Vue.set(this[key], channelId, {});

            if (kind === 'electromagnetic') url = `/electromagnetics/${channelId}`;
            if (kind === 'dominance') url = `/dominances/${channelId}`;
            if (kind === 'companionship') url = `/companionships/${channelId}`;
            if (kind === 'compromise'){
                url = `/compromises`;
                const chObject = channelConstants.find(x => x.id === channelId);

                let gateToQuery = null;

                // we search the gate that the non-dominant user has and query with this gate (for example on 53-42 channel, user has 42 gate)
                for (let gate of chObject.gates) {
                    let gt = this.chart.chart.planets.find(x => x.gate === gate && x.chartId !== extras.dominantChartId);

                    if (gt) gateToQuery = gt.gate;
                }

                url = `/compromises/${gateToQuery}.${channelId}`;
            }

            if (!extras?.saveLocally) this.getContent((`${key}.${channelId}`), url, true);
            else {
                this.getContent('undef', url).then(data => {
                    this.singleAnalysisContent[kind] = data;
                })
            }
        },

        getCenterDynamicContent(key, center) {
            return this.getContent('undef', `/partnership-a-b/centers.${center.id}.${center.dynamics?.type}`).then((data) => {
                for (const comp of data.components) {
                    if (comp.__component === 'dropzone.dynamic-text') {
                        let [title, content] = splitAtFirstH2(comp.content);
                        title = title.replaceAll('<h2>', '').replaceAll('</h2>', '');

                        // replace [a] and [b] appropriately.
                        comp.title = title.replaceAll(
                            '[a]',
                            `<span class="font-weight-bold ${
                                center.dynamics?.a?.isLeft ? 'text-connection-left' : 'text-connection-right'
                            }">${this.getFirstName(center.dynamics?.a?.name)}</span>`).replaceAll(
                            '[b]',
                            `<span class="font-weight-bold ${
                                center.dynamics?.b?.isLeft ? 'text-connection-left' : 'text-connection-right'
                            }">${this.getFirstName(center.dynamics?.b?.name)}</span>`
                        )

                        comp.content = content.replaceAll(
                            '[a]',
                            `<span class="font-weight-bold ${
                                center.dynamics?.a?.isLeft ? 'text-connection-left' : 'text-connection-right'
                            }">${this.getFirstName(center.dynamics?.a?.name)}</span>`).replaceAll(
                            '[b]',
                            `<span class="font-weight-bold ${
                                center.dynamics?.b?.isLeft ? 'text-connection-left' : 'text-connection-right'
                            }">${this.getFirstName(center.dynamics?.b?.name)}</span>`
                        )
                    }
                }
                // try to set `this.xx[center.id]
                Vue.set(this[key], center.id, data.components);
            })
        }
    },
}