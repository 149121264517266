export default{

    advanced: 'Advanced',
    options: 'Options',
    actions: 'Actions',
    account: 'Account',
    chart: 'Main Chart',
    associatedChart: 'Associated Chart',
    change: 'Change',
    save: 'Save',
    cancel: 'Cancel',
    bulkActions: 'Bulk Actions',
    showMore: 'Show More',
    showLess: 'Show Less',
    saveChanges: 'Save Changes',
    saveChart: 'Save to Library',
    lastUpdate: 'Last Updated',
    createdAt: 'Creation Date',
    select: 'Select',
    close: 'Close',
    load: 'Load',
    hi: 'Hi',
    create: 'Create',
    date: 'Date',
    nums:{
        1: '1st',
        2: '2nd',
        3: '3rd',
        4: '4th',
        5: '5th',
        6: '6th'
    },
    audio:{
        forwards:'Jump 15s',
        backwards: 'Back 15s'
    },
    copy: 'Copy',
    copied: 'Copied to clipboard',
    enterName: 'Enter Name',
    country: 'Start typing...',
    city: 'Start typing...',
    loading: 'Loading',
    noResults: 'No search results found!',
    accountOptions:{
        pro_sidebar_version: 'Enable Pro Mode for personal & companionship charts',
        date_input: 'Date Input Format'
    },
    pro: {
        summary: 'Chart Properties',
        potential: 'Advanced Analysis Introduction',
        nourish: 'Dietary Regimen',
        path: 'Environment',
        vision: 'Perspective',
        mind: 'Motivation',
        authority: 'Inner Authority'
    },
    unlockIching: 'Unlock this chart to view Rave I\'Ching line analysis'
}