<template>
  <div class="px-md-3 px-lg-4">

    <div class="mt-4">
      <div class="d-flex gap-5 gap-md-20">
        <span class="icon-gate " style="font-size:20px;"/>
        <div>
          <h4 class="d-flex align-items-center gap-5">  {{$t('hd.gates.name.many')}}</h4>
          <div class="d-flex overflow-x-auto gap-30 gap-md-40 overflow-hidden">
            <big-number :number="statistics.gates.activated" description="Activated"/>
            <hr>
            <big-number :number="statistics.gates.collective" description="Collective"/>
            <hr>
            <big-number :number="statistics.gates.individual" description="Individual"/>
            <hr>
            <big-number :number="statistics.gates.tribal" description="Tribal"/>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="d-flex gap-5 gap-md-20 overflow-auto">
        <span class="icon-lines " style="font-size:20px;"/>
        <div>
          <h4 class="d-flex align-items-center gap-5 ">  {{$t('hd.objects.lines')}}</h4>
          <div class="d-flex overflow-x-auto gap-30 gap-md-40 ">
            <big-number :number="statistics.lines['1']" :description="$t('general.nums.1')"/>
            <hr>
            <big-number :number="statistics.lines['2']" :description="$t('general.nums.2')"/>
            <hr>
            <big-number :number="statistics.lines['3']" :description="$t('general.nums.3')"/>
            <hr>
            <big-number :number="statistics.lines['4']" :description="$t('general.nums.4')"/>
            <hr>
            <big-number :number="statistics.lines['5']" :description="$t('general.nums.5')"/>
            <hr>
            <big-number :number="statistics.lines['6']" :description="$t('general.nums.6')"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import channels from "../../../helpers/rave/constants/channels";
import circuits from "../../../helpers/rave/constants/circuits";
import BigNumber from "../../structure/BigNumber.vue";

export default{
  components: {BigNumber},

  props:{
    chart: Object
  },

  computed:{
    statistics(){
      const lines = {
        '1': 0,
        '2': 0,
        '3': 0,
        '4': 0,
        '5': 0,
        '6': 0
      }

      const gateCounts = {
        activated: new Set(this.chart.chart.planets.map(x => x.gate)).size,
        collective: 0,
        individual: 0,
        tribal: 0
      }

      const usedGates = new Set();

      for(const planet of this.chart.chart.planets){
        lines[planet.line.toString()]++;

        if(usedGates.has(planet.gate)) continue;

        usedGates.add(planet.gate);

        const channel = channels.find(x => x.gates.includes(planet.gate));

        if([circuits.EGO, circuits.DEFENSE].includes(channel.circuit)) gateCounts.tribal++;

        if([circuits.LOGIC, circuits.ABSTRACT].includes(channel.circuit)){
          gateCounts.collective++;
        }

        if([circuits.KNOWING, circuits.CENTERING, circuits.INTEGRATION].includes(channel.circuit)){
          gateCounts.individual++;
        }
      }

      return {
        gates: gateCounts,
        lines
      }

    },
  }
}
</script>
<style lang="scss" scoped>
hr {
  height: 50px;
  margin: auto -10px;
  /* border-reset*/
  border: none;
  border-left: 1px solid rgba(0,0,0, 0.2);
  @media(max-width:540px){
    margin: auto -15px;
    height:35px;
  }

}
.theme--dark{
  hr{
    border-left: 1px solid rgba(255,255,255, 0.3);
  }
}

</style>