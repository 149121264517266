<template>
  <div class="dynamic-zone-renderer unselectable "
  :class="{
      'px-3 pt-2 pb-2 casual-border-radius-small mb-2': isReverseColor,
      'bg-light-but-hoverable': isReverseColor && !toggled,
      'related-link-toggle': isReverseColor && isToggleable,
      'mb-4': !isReverseColor,
      'reverse-bg': isReverseBackground
  }">
    <div v-if="title" class="dzr-title font-bold w-full d-flex justify-content-between align-items-center"
        @click="isToggleable && hasData ? toggle() : ''"
        :class="{
          'cursor-pointer': isToggleable
        }"
        @mouseover="$emit('titleMouseover')"
        @mouseleave="$emit('titleMouseleave')"
    >
      <h5 v-if="title">
        <p class="text-sm font-weight-light my-1">{{type ? type : ''}}</p> <span v-html="title"/>
      </h5>
      <div class="d-flex align-items-center">
         <span v-if="isToggleable && hasData" class="icon-arrow-down ease-in-out duration-300 ml-auto"
               :style="toggled ? 'transform:rotate(180deg);': ''"

         />
        <span v-else-if="isToggleable && !hasData" class="icon-lock" :style="{textColor: isReverseColor ? 'currentColor' : '#D14B49'}"/>
      </div>

    </div>

    <div v-if="!isToggleable || toggled" :class="{ 'mt-3': isToggleable }">

      <slot></slot>
      <div v-for="(component,index) in components" :key="component.__component + '-'+index" class="pb-3">

        <img v-if="getType(component) === 'image'" :src="component.image" loading="lazy" class="casual-border-radius"
            :class="component.full_width ? '' : 'px-4'">
        <h3 v-if="getType(component) === 'title'" class="text-lg" :class="addPaddings ? 'px-4' : ''">{{component.title}}</h3>
        <audio-player v-if="getType(component) === 'audio-player'"
                      :id="component.id + index"
                      :title="component.title"
                      :file-url="component.audio" :align-left="true" class="mb-3" :class="addPaddings ? 'px-4' : ''"></audio-player>


        <div class="regular-html" v-if="getType(component) === 'html'" v-html="component.content" :class="addPaddings ? 'px-4' : ''"></div>

        <video v-if="getType(component) === 'video' && component.video" controls width="100%" class="casual-border-radius">
          test
          <source :src="component.video" />
        </video>
      </div>

      <slot name="after-content"/>
    </div>

  </div>

</template>
<style lang="scss">
.dynamic-zone-renderer{
  .section-toggler{
    transition: all .2s ease-in-out;
    transform:rotate(-90deg);
    &.toggled{

      transform:rotate(0deg);
     }
  }
  &.reverse-bg{
    background:white;
    box-shadow: 0 10px 25px rgba(24, 28, 36, 0.02);
  }
}
.regular-html{
  p:last-child, ul:last-child {
    margin-bottom:0!important;
  }
}
</style>


<script>
import AudioPlayer from "../structure/AudioPlayer";
export default{
  components: {AudioPlayer},
  props:{
    title: String,
    components: Array,
    addPaddings: {
      type: Boolean,
      default: true
    },
    type: String,
    isToggleable: {
      type: Boolean,
      default: false
    },
    isReverseColor: {
      type: Boolean,
      default: false,
    },
    toggledWhenRendered:{
      type: Boolean,
      default: false
    },
    isReverseBackground: Boolean,
  },

  computed:{
    hasData(){
      return (this.components && this.components.length > 0) || this.$slots.default
    }
  },
  data(){
    return{
      toggled: false,
      componentTypes: {
        'dropzone.dynamic-image': 'image',
        'dropzone.dynamic-title': 'title',
        'dropzone.dynamic-audio': 'audio-player',
        'dropzone.dynamic-text': 'html',
        'dropzone.dynamic-video': 'video',
      }
    }
  },
  mounted(){
    this.toggled = this.toggledWhenRendered;
  },
  methods:{

    toggle(){
      this.toggled = !this.toggled;
      if(this.toggled) this.$emit('opened');
      else this.$emit('closed');
    },

    getType(component){
      return this.componentTypes[component.__component];
    }

  }
}
</script>